import { Injectable } from '@angular/core';
import { Price } from './Price';
import {Product, ProductAdapter} from './Product';
import {Adapter} from '../interfaces/adapter.interface';

export class Article {
  id: string;
  locationId: string;
  productId: string;
  ean: string;
  quantity: number;
  totalAmount: number;
  categoryCodes: string[];
  expiryDate: Date;
  currentLocationInsertionDate: Date;
  creationDate: Date;
  modificationDate: Date;
  lotNumber: string;
  creationOrganisationId: string;
  product: Product;
  quantityWithCoefficient: number;
  price: Price;

  constructor(id?: string, locationId?: string, productId?: string, ean?: string, quantity?: number, totalAmount?: number,
              categoryCodes?: string[], expiryDate?: Date, currentLocationInsertionDate?: Date, creationDate?: Date,
              modificationDate?: Date, lotNumber?: string, creationOrganisationId?: string, product?: Product,
              quantityWithCoefficient?: number, price?: Price) {
    this.id = id;
    this.locationId = locationId;
    this.productId = productId;
    this.ean = ean;
    this.quantity = quantity;
    this.totalAmount = totalAmount;
    this.categoryCodes = categoryCodes;
    this.expiryDate = expiryDate;
    this.currentLocationInsertionDate = currentLocationInsertionDate;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.lotNumber = lotNumber;
    this.creationOrganisationId = creationOrganisationId;
    this.product = product;
    this.quantityWithCoefficient = quantityWithCoefficient;
    this.price = price;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ArticleAdapter implements Adapter<Article> {
  adapt(item: any): Article {
    const productAdapter: ProductAdapter = new ProductAdapter();

    return new Article(
      item.id,
      item.locationId,
      item.productId,
      item.ean,
      item.quantity,
      item.totalAmount,
      item.categoryCodes ? item.categoryCodes : [],
      item.expiryDate ? new Date(item.expiryDate.toString()) : undefined,
      item.currentLocationInsertionDate ? new Date(item.currentLocationInsertionDate.toString()) : undefined,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.lotNumber,
      item.creationOrganisationId,
      item.product ? productAdapter.adapt(item.product) : undefined,
      item.quantityWithCoefficient,
      item.price
    );
  }
}
