import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Category} from '../../lib/model/Category';
import {Router} from '@angular/router';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {lastValueFrom, Subscription} from 'rxjs';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {CategoryService} from '../../lib/services/category.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnDestroy {
  public treeControl = new NestedTreeControl<Category>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<Category>();
  private saleChannelChangedSubscription: Subscription;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router,
              public sharedVariablesService: SharedVariablesService,
              private categoryService: CategoryService) {
    this.saleChannelChangedSubscription = this.sharedVariablesService.saleChannelChanged.subscribe((saleChannel) => {
      lastValueFrom(this.categoryService.getCategoriesBySaleChannel(saleChannel, 5)).then((category) => {
        this.sharedVariablesService.rootCategory = category[0];
        this.dataSource.data = category[0].children ? category[0].children : [];
      });
    });
  }

  ngOnDestroy(): void {
    this.saleChannelChangedSubscription.unsubscribe();
  }

  hasChild = (_: number, node: Category) => !!node.children && node.children.length > 0;

  closeMenu(): void {
    this.toggle.emit(false);
  }
}
