import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ProductPropertyScheme, ProductPropertySchemeAdapter } from './ProductPropertyScheme';

export class ProductScheme {
  instanceId: string;
  maxCategoryLevels: number;
  priceLevel: number;
  insertionAuthorized: boolean;
  updateAuthorized: boolean;
  stocksLocation: string;
  pricesLocation: string;
  productManagingOrganisationTypes: string[];
  priceManagingOrganisationTypes: string[];
  priceKeyName: PriceKeyName;
  productPropertySchemes: ProductPropertyScheme[];

  constructor(instanceId: string, maxCategoryLevels: number, priceLevel: number, insertionAuthorized: boolean, updateAuthorized: boolean,
              stocksLocation: string, pricesLocation: string, productManagingOrganisationTypes: string[], priceManagingOrganisationTypes: string[],
              priceKeyName: PriceKeyName, productPropertySchemes: any) {
    this.instanceId = instanceId;
    this.maxCategoryLevels = maxCategoryLevels;
    this.priceLevel = priceLevel;
    this.insertionAuthorized = insertionAuthorized;
    this.updateAuthorized = updateAuthorized;
    this.stocksLocation = stocksLocation;
    this.pricesLocation = pricesLocation;
    this.productManagingOrganisationTypes = productManagingOrganisationTypes;
    this.priceManagingOrganisationTypes = priceManagingOrganisationTypes;
    this.priceKeyName = priceKeyName;
    this.productPropertySchemes = productPropertySchemes;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductSchemeAdapter implements Adapter<ProductScheme> {
  adapt(item: any): ProductScheme {
    const productPropertySchemeAdapter: ProductPropertySchemeAdapter = new ProductPropertySchemeAdapter();
    return new ProductScheme(
      item.instanceId,
      item.maxCategoryLevels,
      item.priceLevel,
      item.insertionAuthorized,
      item.updateAuthorized,
      item.stocksLocation,
      item.pricesLocation,
      item.productManagingOrganisationTypes,
      item.priceManagingOrganisationTypes,
      item.priceKeyName,
      item.productPropertySchemes ? item.productPropertySchemes.map((e) => {
        e = productPropertySchemeAdapter.adapt(e);
        return e;
      }) : []
    );
  }
}

export enum PriceKeyName {
  PRODUCT_ID = 'PRODUCT_ID',
  EAN = 'EAN',
  DECLINATION_ID = 'DECLINATION_ID',
}
