import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { OrderStatus } from './Order';

export class OrderStatusEvent {
  orderStatus: OrderStatus;
  date: Date;
  userId: string;

  constructor(orderStatus?: OrderStatus, date?: Date, userId?: string) {
    this.orderStatus = orderStatus;
    this.date = date;
    this.userId = userId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderStatusEventAdapter implements Adapter<OrderStatusEvent> {

  adapt(item: any): OrderStatusEvent {

    return new OrderStatusEvent(
      item.orderStatus ? (item.orderStatus) : (item.orderLineStatus ? item.orderLineStatus : undefined),
      item.date ? new Date(item.date.toString()) : undefined,
      item.userId
    );
  }
}
