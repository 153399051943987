import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {GoogleAnalyticsService} from '../../lib/services/google-analytics.service';
import {LanguageService} from '../../lib/services/language.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {User} from '../../lib/model/User';
import {AuthenticationService} from '../../lib/services/authentication.service';
import {MyAccountComponent} from '../my-account/my-account.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public searchForm: FormGroup;
  public user: User;
  public showMenu: boolean = false;
  public showCartPanel: boolean = false;
  public animateBadge: boolean = false;
  private transactionSubscription: Subscription;
  private itemsInTransaction: number = 0;
  private readonly showCartInPage: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.sharedVariablesService.showCartInPage = this.sharedVariablesService.config.SHOW_CART_IN_PAGE && window.innerWidth >= 1280;
  }

  constructor(private router: Router,
              public languageService: LanguageService,
              private googleAnalyticsService: GoogleAnalyticsService,
              public sharedVariablesService: SharedVariablesService,
              public authenticationService: AuthenticationService,
              public dialog: MatDialog) {

    this.showCartInPage = this.sharedVariablesService.config.SHOW_CART_IN_PAGE;
    this.getScreenSize(undefined);
  }

  ngOnInit() {
    this.transactionSubscription = this.sharedVariablesService.updatedTransaction.subscribe((transaction) => {
      if (transaction && transaction.items.length !== this.itemsInTransaction) {
        this.animateBadge = true;
        window.setTimeout(() => {
          this.itemsInTransaction = transaction.items.length;
          this.animateBadge = false;
        }, 2000);

        if (this.sharedVariablesService.showCartInPage && this.sharedVariablesService.config.SHOW_CART_IN_PAGE) {
          this.showCartPanel = true;
          this.sharedVariablesService.cartInPageVisible = true;
        }
      } else if (!transaction) {
        this.showCartPanel = false;
        if (this.sharedVariablesService.showCartInPage) {
          this.sharedVariablesService.cartInPageVisible = this.showCartPanel;
        } else {
          this.sharedVariablesService.cartInPageVisible = false;
        }
      }
    });

    // Initialize search form
    this.searchForm = new FormGroup({
      search: new FormControl(undefined),
    });
  }

  ngOnDestroy(): void {
    this.transactionSubscription.unsubscribe();
  }

  search() {
    const term = this.searchForm.get('search').value;

    // Send A Event
    this.googleAnalyticsService.searchEvent(term);

    this.router.navigate(['search'], { queryParams: { term }});
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }

  toggleCart() {
    this.showCartPanel = !this.showCartPanel;
    if (this.sharedVariablesService.showCartInPage) {
      this.sharedVariablesService.cartInPageVisible = this.showCartPanel;
    } else {
      this.sharedVariablesService.cartInPageVisible = false;
    }

    if (this.showCartPanel) {
      // Send GA event
      this.googleAnalyticsService.eventEmitter('click_shopping_cart', { 'itemsQuantity': this.sharedVariablesService.transaction.totalItemQuantity });
    }
  }

  closeCartPanel() {
    if (this.showCartPanel && !this.showCartInPage) {
      this.showCartPanel = false;
    }
  }

  changeLanguage(locale: string) {
    this.languageService.setLanguage(locale, true);
    location.reload();
  }

  openMyAccount(): void {
    const dialogRef = this.dialog.open(MyAccountComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
