import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class OfferConfiguration {
  applicationRule: ApplicationRule;
  combinable: boolean;
  panachable: boolean;
  value: number;

  constructor(applicationRule?: ApplicationRule, combinable?: boolean, panachable?: boolean, value?: number) {
    this.applicationRule = applicationRule;
    this.combinable = combinable;
    this.panachable = panachable;
    this.value = value;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferConfigurationAdapter implements Adapter<OfferConfiguration> {

  adapt(item: any): OfferConfiguration {
    return new OfferConfiguration(
      item.applicationRule,
      item.combinable,
      item.panachable,
      item.value
    );
  }
}

export enum ApplicationRule {
  CHEAPEST_PRODUCT = 'CHEAPEST_PRODUCT',
  MOST_EXPENSIVE_PRODUCT = 'MOST_EXPENSIVE_PRODUCT',
}
