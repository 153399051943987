<div class="row cart-item">
  <div class="col-md-5 col-sm-6 col-xs-12 item-name">
    <div><strong>{{ item.name }}</strong></div>
    <div *ngIf="item.appliedPromotions && item.appliedPromotions.length > 0">
      <div *ngFor="let promotion of item.appliedPromotions" class="promotion">
        <div *ngIf="!isInPack || promotion.discountType !== 'PACK'"><mat-icon class="font-size" color="accent">check</mat-icon> {{ promotion.promotionName }}</div>
      </div>
    </div>
    <div *ngIf="item.options" class="mt5">
      <div *ngFor="let option of item.options" class="item-options">
        <strong>{{ option.productOption.name }} : </strong>
        <span *ngIf="option.productOptionValue">{{ option.productOptionValue.name }}
          <span *ngIf="option.productOptionValue.price > 0"> ({{ option.productOptionValue.price | price }})</span></span>
        <span *ngIf="option.productOptionValues">
          <span class="option-list-item" *ngFor="let productOptionValue of option.productOptionValues">{{ productOptionValue.name }}<span *ngIf="productOptionValue.price > 0"> ({{ productOptionValue.price | price }})</span>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-sm-6 col-xs-12">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 quantity no-padding">
        <div [ngClass]="{ 'readonly': readonly }">
          <!--          <button mat-icon-button class="delete-icon" (click)="deleteFromCart()"><mat-icon>delete_outline</mat-icon></button>-->
          <button *ngIf="!readonly" mat-icon-button color="primary" (click)="decreaseQuantity()"><mat-icon>remove_circle</mat-icon></button>&nbsp;<strong>{{ item.quantity }}</strong>
          &nbsp;<button *ngIf="!readonly" mat-icon-button color="primary" (click)="increaseQuantity()"><mat-icon>add_circle</mat-icon></button>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 text-right price-container no-padding">
        <ng-container *ngIf="!isInPack">
          <span class="initial-price" *ngIf="item.totalDiscountAmount">{{ item.totalAmount + item.totalDiscountAmount | price }}</span>
          <span class="price">{{ item.totalAmount | price }}</span>
        </ng-container>
        <div class="deposit" *ngIf="item.totalDepositAmount > 0"><span class="small">+ {{ 'PRODUCT.ITEM_DEPOSIT' }} </span><strong>{{ item.totalDepositAmount | price }}</strong></div>
      </div>
    </div>
  </div>
</div>
