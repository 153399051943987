import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {LOCALE_ID, NgModule, OnDestroy} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SPINNER, NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {HeaderComponent} from './partials/header/header.component';
import {FooterComponent} from './partials/footer/footer.component';
import {GoUpComponent} from './partials/go-up/go-up.component';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {registerLocaleData} from '@angular/common';
import localFr from '@angular/common/locales/fr';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule} from '@angular/material/form-field';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MenuComponent} from './partials/menu/menu.component';
import {MatTreeModule} from '@angular/material/tree';
import {LanguageService} from './lib/services/language.service';
import {Subscription} from 'rxjs';
import {RouteReuseStrategy} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MAT_TABS_CONFIG, MatTabsModule} from '@angular/material/tabs';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {ERROR_INTERCEPTOR_PROVIDER} from './lib/helpers/error.interceptor';
import {AUTHENTICATION_PROVIDER} from './lib/helpers/auth.interceptor';
import {AuthGuard} from './lib/helpers/auth-guards';
import {CustomReuseStrategy} from './lib/helpers/routing';
import {MatInputModule} from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LibModule} from './lib/lib.module';
import {ResetPasswordComponent} from './partials/reset-password/reset-password.component';
import {ClickOutsideDirective} from './lib/directives/click-outside.directive';
import {CartPanelComponent} from './partials/cart-panel/cart-panel.component';
import {CartItemComponent} from './partials/cart-item/cart-item.component';
import {OptionsDialogComponent} from './partials/options-dialog/options-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {LoginComponent} from './pages/login/login.component';
import {OauthComponent} from './pages/login/oauth.component';
import {MatCardModule} from '@angular/material/card';
import {ProductComponent} from './pages/product/product.component';
import {ProductsComponent} from './pages/products/products.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatChipsModule} from '@angular/material/chips';
import {SlideshowModule} from 'ng-simple-slideshow';
import {ProductItemComponent} from './partials/product-item/product-item.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MyAccountComponent} from './partials/my-account/my-account.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import { ProductItemSmallComponent } from './partials/product-item-small/product-item-small.component';
import { CartComponent } from './pages/cart/cart.component';
import { CustomerOrdersComponent } from './partials/customer-orders/customer-orders.component';

export function createTranslateLoader(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// Ngx mask
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Mat form fields appearance
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

// Configure loader
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#008949',
  fgsPosition: 'center-center',
  fgsSize: 80,
  fgsType: 'three-strings',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 220,
  logoUrl: 'assets/images/giphar-groupe-logo.png',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(255, 255, 255, 0.8)',
  pbColor: '#008949',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#000000',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300
};

// Register the used locale file on your own.
registerLocaleData(localFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    GoUpComponent,
    MenuComponent,
    ResetPasswordComponent,
    ClickOutsideDirective,
    CartPanelComponent,
    CartItemComponent,
    OptionsDialogComponent,
    LoginComponent,
    OauthComponent,
    ProductComponent,
    ProductsComponent,
    ProductItemComponent,
    MyAccountComponent,
    ProductItemSmallComponent,
    CartComponent,
    CustomerOrdersComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    MatIconModule,
    MatButtonModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatMenuModule,
    MatFormFieldModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatInputModule,
    LibModule,
    MatCheckboxModule,
    FormsModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    InfiniteScrollModule,
    MatChipsModule,
    SlideshowModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTabsModule,
    NgxMaskModule.forRoot(),
    MatStepperModule,
    MatExpansionModule,
  ],
  providers: [
    AuthGuard,
    AUTHENTICATION_PROVIDER,
    ERROR_INTERCEPTOR_PROVIDER,
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_TABS_CONFIG, useValue: {animationDuration: 0}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance},
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnDestroy {
  private languageSubscription: Subscription;

  constructor(private translate: TranslateService,
              private languageService: LanguageService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.translate.use(this.languageService.getCurrentLanguage());
    this.languageSubscription = this.languageService.languageChanged.subscribe((locale) => {
      this.translate.use(this.languageService.returnLanguageOrFallback(locale));
    });

    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom.svg'));
  }

  ngOnDestroy() {
    this.languageSubscription.unsubscribe();
  }
}
