import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { AdditionalTax } from './AdditionalTax';
import { Customer, CustomerAdapter } from './Customer';
import { Delivery } from './Delivery';
import { Item, ItemAdapter } from './Item';
import { Pack, PackAdapter } from './Pack';
import { Product } from './Product';
import { ProductVariant } from './ProductVariant';
import { Status } from './Status';
import { TransactionProperties, TransactionPropertiesAdapter } from './TransactionProperties';

export interface ItemsMapObject {
  // Product id -> ItemQuantity[]
  [productId: string]: ItemQuantity;
}

export interface ItemQuantity {
  // Ean -> quantity
  [ean: string]: number;
}

export interface ItemsWithOptionMapObject {
  [index: string]: Item;
}

export class Transaction {
  id: string;
  saleChannelCode: string;
  customer: Customer;
  properties: TransactionProperties;
  organisationId: string;
  sellerId: string;
  sellerDeviceId: string;
  totalAmount: number = 0;
  totalLinesDiscountAmount: number = 0;
  totalManualDiscountAmount: number = 0;
  totalDiscountAmount: number = 0;
  totalPurchasingAmount: number = 0;
  totalMarginRate: number = 0;
  totalSalesUnitCount: number = 0;
  totalDepositAmount: number = 0;
  totalItemQuantity: number = 0;
  packs: Pack[] = [];
  type: string;
  amount: string;
  validationDate: Date;
  currentStatus: Status;
  insufficientStock: boolean;
  delivery: Delivery;
  additionalTaxes: AdditionalTax[] = [];
  totalDeliveryCostAmount: number = 0;
  totalDeliveryCostGrossAmount: number = 0;
  totalDeliveryVatAmount: number = 0;
  appointmentMakingDirective: string;
  // Local only
  itemsWithOptions: ItemsWithOptionMapObject = {};
  customerId: string;
  private _items: Item[] = [];
  /**
   * Object containing items of the transaction (one item per ean property).
   */
  private itemQuantities: ItemsMapObject = {};

  constructor(id: string,
              saleChannelCode: string,
              customer: Customer,
              properties: TransactionProperties,
              organisationId: string,
              sellerId: string,
              sellerDeviceId: string,
              totalAmount?: number,
              totalLinesDiscountAmount?: number,
              totalManualDiscountAmount?: number,
              totalDiscountAmount?: number,
              totalPurchasingAmount?: number,
              totalMarginRate?: number,
              totalSalesUnitCount?: number,
              totalDepositAmount?: number,
              totalItemQuantity?: number,
              items?: Item[],
              itemQuantities?: ItemsMapObject,
              packs?: Pack[],
              type?: string,
              amount?: string,
              validationDate?: Date,
              currentStatus?: Status,
              insufficientStock: boolean = false,
              delivery?: Delivery,
              additionalTaxes?: AdditionalTax[],
              totalDeliveryCostAmount?,
              totalDeliveryCostGrossAmount?,
              totalDeliveryVatAmount?,
              appointmentMakingDirective?) {
    this.id = id;
    this.saleChannelCode = saleChannelCode;
    this.customer = customer;
    this.properties = properties;
    this.organisationId = organisationId;
    this.sellerId = sellerId;
    this.sellerDeviceId = sellerDeviceId;
    this.totalAmount = totalAmount;
    this.totalLinesDiscountAmount = totalLinesDiscountAmount;
    this.totalManualDiscountAmount = totalManualDiscountAmount;
    this.totalDiscountAmount = totalDiscountAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.totalMarginRate = totalMarginRate;
    this.totalSalesUnitCount = totalSalesUnitCount;
    this.totalDepositAmount = totalDepositAmount;
    this.totalItemQuantity = totalItemQuantity;
    this._items = items;
    this.itemQuantities = itemQuantities;
    this.packs = packs;
    this.type = type;
    this.amount = amount;
    this.validationDate = validationDate;
    this.currentStatus = currentStatus;
    this.insufficientStock = insufficientStock;
    this.delivery = delivery;
    this.additionalTaxes = additionalTaxes ? additionalTaxes : [];
    this.totalDeliveryCostAmount = totalDeliveryCostAmount;
    this.totalDeliveryCostGrossAmount = totalDeliveryCostGrossAmount;
    this.totalDeliveryVatAmount = totalDeliveryVatAmount;
    this.appointmentMakingDirective = appointmentMakingDirective;

    // If item quantities structure not sent by service, we build it locally
    if ((!this.itemQuantities || Object.entries(this.itemQuantities).length === 0) && (this.items && this.items.length > 0)) {
      this.itemsWithOptions = {};
      for (const [index, item] of this.items.entries()) {
        if (!item['options'] || item.options.length === 0) {
          if (!this.itemQuantities[item.productId]) {
            this.itemQuantities[item.productId] = {};
          }
          if (this.itemQuantities[item.productId][item.ean]) {
            this.itemQuantities[item.productId][item.ean] += item.quantity;
          } else {
            this.itemQuantities[item.productId][item.ean] = item.quantity;
          }
        } else {
          this.itemsWithOptions[item.rank] = item;
        }
      }
    }
  }

  get items(): Item[] {
    return this._items;
  }

  get itemsAsObject(): ItemsMapObject {
    return this.itemQuantities;
  }

  /**
   * Remove all products from transaction.
   */
  public clearItems() {
    this._items = [];
    this.itemQuantities = {};
    this.itemsWithOptions = {};
  }

  /**
   * Reinject items objects from itemsMapObject into transaction items array.
   */
  public reinjectItemsMapObject(): Item[] {
    // Transform this.itemsMapObject into an array of Item objects with ean and quantity properties
    let items = [];
    for (const [, value] of Object.entries(this.itemsWithOptions)) {
      items.push(value);
    }

    if (this.itemQuantities) {
      for (const [, value] of Object.entries(this.itemQuantities)) {
        for (const [ean, quantity] of Object.entries(value)) {
          items.push(<Item>{ean, quantity});
        }
      }
    }

    return items;
  }

  /**
   * Edit / remove a product-item to/from the transaction using provided quantity.
   * @param item
   * @param quantity
   * @param absolute Whether the quantity provided is absolute or relative
   */
  public updateProductInTransaction(item: ProductVariant|Item|Product, quantity: number = 0, absolute: boolean = true) {
    const productId = item['productId'] ? item['productId'] : item['id'];
    // If quantity is 0 or NaN, delete item
    if ((quantity === 0 || isNaN(quantity)) && this.itemQuantities[productId] && this.itemQuantities[productId][item.ean]) {
      delete this.itemQuantities[productId][item.ean];
      if (this.itemQuantities[productId].length === 0) {
        delete this.itemQuantities[productId];
      }
    } else { // Add or update
      if (!this.itemQuantities[productId]) {
        this.itemQuantities[productId] = {};
      }
      if (absolute) {
        this.itemQuantities[productId][item.ean] = quantity;
      } else {
        this.itemQuantities[productId][item.ean] = this.itemQuantities[productId][item.ean] ? this.itemQuantities[productId][item.ean] : 0;
        this.itemQuantities[productId][item.ean] += quantity;
      }
    }
  }

  /**
   * Return true whether the given product-item is in transaction (works only with items without options).
   * @param product
   */
  public containsProduct(product: Product): boolean {
    return this.itemQuantities.hasOwnProperty(product.id);
  }

  /**
   * Return true whether the given product-item is in transaction with options.
   * @param product
   */
  public containsProductWithOption(product: Product): boolean {
    for (const [key, value] of Object.entries(this.itemsWithOptions)) {
      if (value.productId === product.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Return true whether any of the given ean for product-item is in transaction.
   * @param productId
   * @param eans
   */
  public containsDeclinationEan(productId: string, eans: string[]): boolean {
    return this.itemQuantities.hasOwnProperty(productId) && Object.keys(this.itemQuantities[productId]).some(v => eans.indexOf(v) !== -1);
  }

  public prepareForJSON(): any {
    let object = this;
    const target = {};
    const itemAdapter: ItemAdapter = new ItemAdapter();
    const customerAdapter: CustomerAdapter = new CustomerAdapter();

    // We prepare and clean transaction
    Object.assign(target, object);
    if (object.customer) {
      target['customer'] = customerAdapter.prepare(object.customer);
    }
    target['items'] = target['_items'].map((elt) => itemAdapter.prepare(elt));

    return target;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    const transactionPropertiesAdapter = new TransactionPropertiesAdapter();
    const customerAdapter = new CustomerAdapter();
    const itemAdapter = new ItemAdapter();
    const packAdapter = new PackAdapter();

    return new Transaction(
      item.id,
      item.saleChannelCode ? item.saleChannelCode : (item.saleChannel ? 'B2BWEB' : undefined),
      item.customer ? customerAdapter.adapt(item.customer) : undefined,
      transactionPropertiesAdapter.adapt(item.properties),
      item.organisationId,
      item.sellerId,
      item.sellerDeviceId,
      item.totalAmount,
      item.totalLinesDiscountAmount,
      item.totalManualDiscountAmount,
      item.totalDiscountAmount,
      item.totalPurchasingAmount,
      item.totalMarginRate,
      item.totalSalesUnitCount,
      item.totalDepositAmount,
      item.totalItemQuantity,
      item.items ? item.items.map(elt => itemAdapter.adapt(elt)) : [],
      item.itemQuantities ? item.itemQuantities : {},
      item.packs ? item.packs.map(elt => packAdapter.adapt(elt)) : [],
      item.type,
      item.amount,
      item.validationDate ? new Date(item.validationDate) : undefined,
      item.currentStatus,
      item.insufficientStock,
      item.delivery,
      item.additionalTaxes,
      item.totalDeliveryCostAmount,
      item.totalDeliveryCostGrossAmount,
      item.totalDeliveryVatAmount,
      item.appointmentMakingDirective
    );
  }

  prepare(object: Transaction): any {
    const target = {};
    const itemAdapter: ItemAdapter = new ItemAdapter();
    const customerAdapter: CustomerAdapter = new CustomerAdapter();

    // We prepare and clean transaction
    Object.assign(target, object);
    if (object.validationDate) {
      target['validationDate'] = target['validationDate'].toISOString();
    }
    if (!object.customerId && object.customer) {
      target['customer'] = customerAdapter.prepare(object.customer);
    } else {
      delete target['customer'];
    }
    target['items'] = object.reinjectItemsMapObject().map((elt) => itemAdapter.prepare(elt));
    delete target['_items'];
    delete target['itemsMapObject'];
    delete target['packs'];
    delete target['productIds'];
    delete target['additionalTaxes'];

    return target;
  }
}
