import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfferPage, OfferPageAdapter } from '../model/OfferPage';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends ApiService {

  constructor(private http: HttpClient,
              private offerPageAdapter: OfferPageAdapter) {
    super();
  }

  getOffers(page: number = 0, pageSize: number = 20, discountType?: string): Observable<OfferPage> {
    let params = new HttpParams()
      .set('size', pageSize.toString())
      .set('page', page.toString());

    if (discountType) {
      params = params.set('discountType', discountType);
    }

    return this.http.get<OfferPage>(this.baseUri + 'offers', {params})
      .pipe(map((item) => this.offerPageAdapter.adapt(item)));
  }
}
