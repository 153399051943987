<div class="products" [ngClass]="{ 'with-right-cart-panel': sharedVariablesService.cartInPageVisible, 'with-search-bar': sharedVariablesService.config.SHOW_SEARCHBAR }">
  <div class="container carousel" *ngIf="carouselAdvertisementsImages.length > 0">
    <slideshow [minHeight]="'178px'"
               [autoPlay]="true"
               [showArrows]="false"
               [showDots]="true"
               [autoPlayInterval]="6000"
               [backgroundSize]="'contain'"
               [imageUrls]="carouselAdvertisementsImages"
               [lazyLoad]="carouselAdvertisementsImages?.length > 1"
               [autoPlayWaitForLazyLoad]="true">
    </slideshow>
  </div>
  <div class="container">
    <h2 *ngIf="packs && packs?.length > 0" class="mb0 mt10">Tous les produits</h2>
    <div *ngIf="categoriesToDisplay && categoriesToDisplay.length > 0" class="hidden-xs visible-sm visible-md visible-lg">
      <div class="subcategories" *ngIf="category">
        <button mat-button [ngClass]="{ 'mat-stroked-button': category.parentCategoryCode, 'mat-flat-button': !category.parentCategoryCode }" color="primary" [routerLink]="'/catalogue/'" (click)="clickCategory(undefined)">{{ 'PRODUCTS.ALL_PRODUCTS' | translate }}</button>
        <button mat-button [ngClass]="{ 'mat-stroked-button': category.code !== subcategory.code, 'mat-flat-button': category.code === subcategory.code }" color="primary" *ngFor="let subcategory of categoriesToDisplay" [routerLink]="'/catalogue/' + subcategory.code" (click)="clickCategory(subcategory.name)">{{subcategory.name}}</button>
      </div>
    </div>
    <div *ngIf="categoriesToDisplay && categoriesToDisplay.length > 0" class="visible-xs">
      <mat-form-field class="full-width category-select" *ngIf="category">
        <mat-select [value]="category.code" (selectionChange)="navigateTo($event.value)">
          <mat-option *ngIf="allProductsCategoryCode" [value]="allProductsCategoryCode">{{ 'PRODUCTS.ALL_PRODUCTS' | translate }}</mat-option>
          <mat-option *ngFor="let subcategory of categoriesToDisplay" [value]="subcategory.code">
            {{ subcategory.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div *ngIf="searchTerm">
        <h2>{{ 'PRODUCTS.SEARCH_RESULTS_FOR' | translate }}
          <mat-chip-list>
            <mat-chip color="primary" selected
                      [selectable]="true"
                      [removable]="true"
                      (removed)="endSearch()">
              {{ searchTerm }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </h2>
      </div>
      <div infiniteScroll [infiniteScrollDisabled]="disableScroll || allProductsLoaded" (scrolled)="loadProducts()" ngxUiLoaderBlurred blur="3">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let product of products">
            <app-product-item [product]="product"></app-product-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<app-cart-fab></app-cart-fab>-->
