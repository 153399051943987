import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Option} from '../../lib/model/Option';
import {Product} from '../../lib/model/Product';

export interface DialogOptionsData {
  options: Option[];
  product: Product;
}

export interface MultipleValuesOptions {
  [optionId: string]: {
    maxValueChoiceCount: number,
    allowExtraValueChoice: boolean,
    selected: { [optionValueId: string]: boolean }
  };
}

@Component({
  selector: 'app-options-dialog',
  templateUrl: './options-dialog.component.html',
  styleUrls: ['./options-dialog.component.scss']
})
export class OptionsDialogComponent implements OnInit {
  public form: FormGroup;
  public multipleValuesOptions: MultipleValuesOptions = {};

  constructor(public dialogRef: MatDialogRef<OptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogOptionsData) {
  }

  ngOnInit() {
    this.form = new FormGroup({});
    for (const option of this.data.product.options) {
      if (option.type === 'VALUE_CHOICE') {
        const formControl: FormControl = new FormControl(undefined, Validators.required);
        this.form.addControl(option.id, formControl);
      } else if (option.type === 'MULTIPLE_VALUE_CHOICE') {
        this.multipleValuesOptions[option.id] = {
          maxValueChoiceCount: option.maxValueChoiceCount,
          allowExtraValueChoice: option.allowExtraValueChoice,
          selected: {}
        };
        for (const optionValue of option.values) {
          this.multipleValuesOptions[option.id]['selected'][optionValue.id] = false;
        }
      } else {
        const formControl: FormControl = new FormControl(undefined);
        this.form.addControl(option.id, formControl);
      }
    }
  }

  close(): void {
    this.dialogRef.close(undefined);
  }

  confirm() {
    for (const productOption of this.data.product.options) {
      if (this.form.value[productOption.id]) {
        if (productOption.type === 'VALUE_CHOICE') {
          this.data.options.push(new Option(productOption.id, this.form.value[productOption.id]));
        } else if (productOption.type === 'TEXT') {
          this.data.options.push(new Option(productOption.id, undefined, undefined, undefined, undefined, [this.form.value[productOption.id]]));
        }
      }
    }
    // We add multiple select options values
    for (const [optionId, value] of Object.entries(this.multipleValuesOptions)) {
      const selectedOptionValuesIds = [];
      for (const [optionValueId, isSelected] of Object.entries(value['selected'])) {
        if (isSelected) {
          selectedOptionValuesIds.push(optionValueId);
        }
      }
      this.data.options.push(new Option(optionId, undefined, undefined, undefined, undefined, selectedOptionValuesIds));
    }

    this.dialogRef.close(this.data.options);
  }

  isMultipleOptionCompleted(optionId: string): boolean {
    let count: number = 0;
    Object.values(this.multipleValuesOptions[optionId]['selected']).forEach((value) => {
      if (value) {
        count++;
      }
    });

    if (this.multipleValuesOptions[optionId].maxValueChoiceCount) {
      return count >= this.multipleValuesOptions[optionId].maxValueChoiceCount;
    } else {
      return count >= 1;
    }
  }

  isMultipleOptionExtra(optionId: string, optionValueId: string) {
    if (this.isMultipleOptionCompleted(optionId) && this.multipleValuesOptions[optionId].maxValueChoiceCount) {
      if (!this.multipleValuesOptions[optionId]['selected'][optionValueId] && this.multipleValuesOptions[optionId].allowExtraValueChoice) {
        return true;
      } else if (this.multipleValuesOptions[optionId].allowExtraValueChoice) {
        let count: number = 0;
        for (const [key, value] of Object.entries(this.multipleValuesOptions[optionId]['selected'])) {
          if (key !== optionValueId && value) {
            count++;
          } else if (key === optionValueId) {
            return count >= this.multipleValuesOptions[optionId].maxValueChoiceCount;
          }
        }
      } else {
        return false;
      }
    }
    return false;
  }

  allOptionsCompleted(): boolean {
    for (const productOption of this.data.product.options) {
      if (productOption.type === 'VALUE_CHOICE' && !this.form.value[productOption.id]) {
        return false;
      }
      if (productOption.type === 'MULTIPLE_VALUE_CHOICE') {
        if (!this.isMultipleOptionCompleted(productOption.id)) {
          return false;
        }
      }
    }
    return true;
  }

  computeOptionsPrice(): number {
    let total: number = 0;

    for (const productOption of this.data.product.options) {
      if (productOption.type === 'VALUE_CHOICE' && this.form.value[productOption.id]) {
        total += productOption.price;
        for (let productOptionValue of productOption.values) {
          if (this.form.value[productOption.id] === productOptionValue.id) {
            total += productOptionValue.price;
          }
        }
      }
      if (productOption.type === 'MULTIPLE_VALUE_CHOICE') {
        for (const productOptionValue of productOption.values) {
          if (this.multipleValuesOptions[productOption.id]['selected'][productOptionValue.id]) {
            total += productOptionValue.price;
          }
        }
        // Add extra if needed
        if (productOption.allowExtraValueChoice) {
          let count: number = 0;
          for (const [key, value] of Object.entries(this.multipleValuesOptions[productOption.id]['selected'])) {
            if (value === true) {
              count++;
            }
          }
          if (count > 0) {
            total += productOption.pricePerExtraValueChoice * (count - productOption.maxValueChoiceCount);
          }
        }
      }
    }
    return total;
  }

  noOption() {
    this.dialogRef.close([]);
  }
}
