import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricePipe} from './helpers/price.pipe';
import {DateformatPipe} from './helpers/dateformat.pipe';
import {AdvertisementService} from './services/advertisement.service';
import {CustomerService} from './services/customer.service';
import {FormatDatePipe} from './helpers/FormatDate.pipe';


@NgModule({
  declarations: [
    PricePipe,
    DateformatPipe,
    FormatDatePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PricePipe,
    DateformatPipe,
    FormatDatePipe,
  ],
  providers: [
    AdvertisementService,
    CustomerService,
  ]
})
export class LibModule {
}
