import { Injectable } from '@angular/core';
import { Organisation, OrganisationAdapter } from './Organisation';
import { Adapter } from '../interfaces/adapter.interface';

export class User {
  id: string;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  groups: string[];
  organisations: Organisation[];
  organisation: Organisation;
  locale: string;
  authType: string;

  constructor(id: string, email: string, token: string, firstName: string, lastName: string, groups: string[],
              organisations: any[], organisation: Organisation, locale: string, authType?: string) {
    this.id = id;
    this.email = email;
    this.token = token;
    this.firstName = firstName;
    this.lastName = lastName;
    this.groups = groups;
    this.organisations = organisations;
    this.organisation = organisation;
    this.locale = locale;
    this.authType = authType;

    if (!this.organisation && this.organisations && this.organisations.length > 0) {
      this.organisation = this.organisations[0];
      // We look for the first OUTLET organisation of the user/customer
      for (const org of this.organisations) {
        if (org.type === 'OUTLET') {
          this.organisation = org;
          return;
        }
      }
    }
  }

  /**
   * Select an organisation based on its id for the current user.
   * @param organisationId
   */
  public selectOrganisation(organisationId: string) {
    for (const organisation of this .organisations) {
      if (organisation.id === organisationId) {
        this.organisation = organisation;
        return;
      }
    }
  }

  /**
   * Return whether user is a customer.
   */
  public isCustomer(): boolean {
    return this.groups.indexOf('ROLE_CUSTOMER') > -1;
  }

  /**
   * Return whether user is instance manager.
   */
  public isInstanceManager(): boolean {
    return (this.groups && this.groups.indexOf('ROLE_INSTANCE_MANAGER') > -1);
  }

  /**
   * Return whether user is a store manager (can validate transactions).
   */
  public isStoreManager(): boolean {
    return this.isInstanceManager() || this.groups.indexOf('STORE_MANAGER') > -1;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserAdapter implements Adapter<User> {
  adapt(item: any): User {
    const organisationAdapter = new OrganisationAdapter();

    return new User(item.id,
      item.email,
      item.token,
      item.firstName,
      item.lastName,
      item.groups,
      item.organisations.map(elt => organisationAdapter.adapt(elt)),
      item.organisation ? organisationAdapter.adapt(item.organisation) : undefined,
      item.locale,
      item.authType
      );
  }
}
