import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Injector, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedVariablesService } from '../services/shared-variables.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(public sharedVariablesService: SharedVariablesService,
              private injector: Injector,
              private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add authorization header with auth credentials if available
    const currentUser = this.authenticationService.getCurrentUser();

    if (request.url.indexOf('authenticate') < 0) {
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + currentUser.token,
          },
        });
      }

      if (this.sharedVariablesService && this.sharedVariablesService.organisation || this.sharedVariablesService.config.ROOT_ORGANISATION_ID) {
        let organisationId: string = this.sharedVariablesService.organisation ? this.sharedVariablesService.organisation.id : this.sharedVariablesService.config.ROOT_ORGANISATION_ID;
        // We will keep organisationId if defined in request
        if (request.headers.has('organisationId')) {
          organisationId = request.headers.get('organisationId');
        }

        request = request.clone({
          setHeaders: {
            organisationId,
          },
        });
      }
    }

    return next.handle(request);
  }
}

export const AUTHENTICATION_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

