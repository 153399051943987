import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Page } from './Page';
import { Promotion, PromotionAdapter } from './Promotion';

export class PromotionPage extends Page {
  content: Promotion[];

  constructor(totalPages?: number, totalElements?: number, size?: number, currentPage?: number, content?: Promotion[]) {
    super(totalPages, totalElements, size, currentPage);
    this.content = content;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PromotionPageAdapter implements Adapter<PromotionPage> {

  adapt(item: any): PromotionPage {
    const promotionAdapter: PromotionAdapter = new PromotionAdapter();

    return new PromotionPage(
      item.totalPages,
      item.totalElements,
      item.size,
      item.number,
      item.content ? item.content.map(e => promotionAdapter.adapt(e)) : []
    );
  }
}
