import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class LocationFormat {
  id: string;
  height: number;
  width: number;
  depth: number;

  constructor(id?: string, height?: number, width?: number, depth?: number) {
    this.id = id;
    this.height = height;
    this.width = width;
    this.depth = depth;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocationFormatAdapter implements Adapter<LocationFormat> {
  adapt(item: any): LocationFormat {
    return new LocationFormat(
      item.id,
      item.height,
      item.width,
      item.depth
    );
  }
}
