import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Address, AddressAdapter } from './Address';
import moment from 'moment';

export class Customer {
  firstName: string;
  lastName: string;
  postalCode: string;
  id?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  loyaltyCardNumber?: string;
  externalpaymentAlias?: string;
  civility?: string;
  birthDay?: Date;
  customerGroups?: string[];
  locale?: string;
  groups?: string[];
  organisationIds: string[];

  password: string;

  customerType: CustomerType;
  registrationNumber?: string;
  companyName?: string;
  legalForm?: string;
  intracommunityVAT?: string;

  address?: Address;
  addresses?: Address[];

  optins?: any;
  generalTermsOfUse?: boolean;

  advertising: boolean;
  modificationDate?: Date;
  creationDate?: Date;

  sourceOrganisationId?: string;

  active?: boolean;

  authType?: string;

  linkedOrganisationId?: string;

  externalId?: string;

  avatarUri?: string;

  sendInvoiceByMail?: boolean;

  customerLoyalty?: any;

  properties: { [name: string]: string[] };
  priceScaleCode?: string;

  activationDate?: any;
  activationCode?: string;
  activationValidity?: any;

  customField1?: string[];
  customField2?: string[];
  customField3?: string[];
  customField4?: string[];
  customField5?: string[];

  token: string;

  constructor(firstName?: string, lastName?: string, postalCode?: string, id?: string, email?: string, phone?: string, mobile?: string,
              loyaltyCardNumber?: string, externalpaymentAlias?: string, civility?: string, birthDay?: Date, customerGroups?: string[],
              locale?: string, groups?: string[], organisationIds?: string[], customerType?: CustomerType, registrationNumber?: string,
              companyName?: string, legalForm?: string, intracommunityVAT?: string, address?: Address, addresses?: Address[], optins?: any,
              generalTermsOfUse: boolean = false, advertising: boolean = false, modificationDate?: Date, creationDate?: Date, sourceOrganisationId?: string,
              active: boolean = false, authType?: string, linkedOrganisationId?: string, externalId?: string, avatarUri?: string,
              sendInvoiceByMail: boolean = false, customerLoyalty?: any, properties?: any, priceScaleCode?: string, activationDate?: any,
              activationCode?: string, activationValidity?: any, customField1?: string[], customField2?: string[], customField3?: string[],
              customField4?: string[], customField5?: string[], token?: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.postalCode = postalCode;
    this.id = id;
    this.email = email;
    this.phone = phone;
    this.mobile = mobile;
    this.loyaltyCardNumber = loyaltyCardNumber;
    this.externalpaymentAlias = externalpaymentAlias;
    this.civility = civility;
    this.birthDay = birthDay;
    this.customerGroups = customerGroups;
    this.locale = locale;
    this.groups = groups;
    this.organisationIds = organisationIds;
    this.customerType = customerType;
    this.registrationNumber = registrationNumber;
    this.companyName = companyName;
    this.legalForm = legalForm;
    this.intracommunityVAT = intracommunityVAT;
    this.address = address;
    this.addresses = addresses;
    this.optins = optins;
    this.generalTermsOfUse = generalTermsOfUse;
    this.advertising = advertising;
    this.modificationDate = modificationDate;
    this.creationDate = creationDate;
    this.sourceOrganisationId = sourceOrganisationId;
    this.active = active;
    this.authType = authType;
    this.linkedOrganisationId = linkedOrganisationId;
    this.externalId = externalId;
    this.avatarUri = avatarUri;
    this.sendInvoiceByMail = sendInvoiceByMail;
    this.customerLoyalty = customerLoyalty;
    this.properties = properties;
    this.priceScaleCode = priceScaleCode;
    this.activationDate = activationDate;
    this.activationCode = activationCode;
    this.activationValidity = activationValidity;
    this.customField1 = customField1;
    this.customField2 = customField2;
    this.customField3 = customField3;
    this.customField4 = customField4;
    this.customField5 = customField5;
    this.token = token;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerAdapter implements Adapter<Customer> {
  adapt(item: any): Customer {
    const addressAdapter: AddressAdapter = new AddressAdapter();

    return new Customer(
      item.firstName,
      item.lastName,
      item.postalCode,
      item.id,
      item.email,
      item.phone,
      item.mobile,
      item.loyaltyCardNumber,
      item.externalpaymentAlias,
      item.civility,
      item.birthDay ? new Date(item.birthDay) : undefined,
      item.customerGroups,
      item.locale,
      item.groups,
      item.organisationIds,
      item.customerType,
      item.registrationNumber,
      item.companyName,
      item.legalForm,
      item.intracommunityVAT,
      item.address ? addressAdapter.adapt(item.address) : undefined,
      item.addresses ? item.addresses.map(e => addressAdapter.adapt(e)) : [],
      item.optins,
      item.generalTermsOfUse,
      item.advertising,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.sourceOrganisationId,
      item.active,
      item.authType,
      item.linkedOrganisationId,
      item.externalId,
      item.avatarUri,
      item.sendInvoiceByMail,
      item.customerLoyalty,
      item.properties,
      item.priceScaleCode,
      item.activationDate,
      item.activationCode,
      item.activationValidity,
      item.customField1,
      item.customField2,
      item.customField3,
      item.customField4,
      item.customField5,
      item.token
    );
  }

  prepare(item: Customer): any {
    const target = {};

    Object.assign(target, item);
    if (item.birthDay) {
      target['birthDay'] = moment(item.birthDay).toISOString().substr(0, 10);
    }

    target['customerId'] = item.id;
    return target;
  }
}

export enum CustomerType {
  PRIVATE = 'PRIVATE',
  PROFESSIONAL = 'PROFESSIONAL',
  TECHNICAL = 'TECHNICAL',
}
