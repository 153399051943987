import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class ProductPropertyScheme {
  code: string;
  name: string;
  visible: number;
  indexed: boolean;
  rank: number;
  visibleInProductList: boolean = false;
  displayMode: 'TAB' | 'LIST' = 'LIST';
  active: boolean;
  values: any[];

  constructor(code: string,
              name: string,
              visible: number,
              indexed: boolean,
              rank: number,
              visibleInProductList: boolean,
              displayMode: 'TAB' | 'LIST',
              active: boolean,
              values: any[]) {
    this.code = code;
    this.name = name;
    this.visible = visible;
    this.indexed = indexed;
    this.rank = rank;
    this.visibleInProductList = visibleInProductList;
    this.displayMode = displayMode;
    this.active = active;
    this.values = values;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductPropertySchemeAdapter implements Adapter<ProductPropertyScheme> {
  adapt(item: any): ProductPropertyScheme {
    return new ProductPropertyScheme(
      item.code,
      item.name,
      item.visible,
      item.indexed,
      item.rank,
      item.visibleInProductList,
      item.displayMode,
      item.active,
      item.values
    );
  }
}
