<article class="product" *ngIf="product" [ngClass]="{ 'with-right-cart-panel': sharedVariablesService.cartInPageVisible, 'with-search-bar': sharedVariablesService.config.SHOW_SEARCHBAR }">
  <div class="container">
    <button class="back-button" mat-stroked-button (click)="goBack()"><mat-icon>keyboard_arrow_left</mat-icon> Retour</button>
    <h1>{{ product.getNameOrCommercialName() }}</h1>
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 mb10 text-center" *ngIf="product.images.length > 0">
        <img class="product-image" src="{{ productImage.url }}" alt="{{ product.getNameOrCommercialName() }}">
        <div class="text-left" *ngIf="product.images && product.images.length > 1">
          <img *ngFor="let image of product.images; let i = index" src="{{ image.url }}" alt="{{ product.name }}" class="small-image" (click)="selectImage(image, i)">
        </div>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 right-side">
        <div>
          <div class="tags">
            <ng-container *ngFor="let tagId of product.tags">
              <img class="tag-image" *ngIf="getTag(tagId) && getTag(tagId).url" [src]="getTag(tagId).url" [alt]="getTag(tagId).name"/>
              <span class="tag" *ngIf="getTag(tagId) && !getTag(tagId).url && getTag(tagId).name">{{ getTag(tagId).name }}</span>
              <span class="tag" *ngIf="!getTag(tagId)">{{ tagId }}</span>
            </ng-container>
          </div>
          <p [innerHTML]="product.description"></p>
          <p><strong>Référence</strong> : {{ product.id }}</p>
          <div class="mt10 mb10" *ngIf="product.weight > 0"><strong>Poids</strong> : {{ product.weight | number: '1.3' }} kg</div>
          <ng-container *ngIf="product.properties">
            <div *ngFor="let propertyScheme of getProductScheme()?.productPropertySchemes" class="mt10 mb10">
              <ng-container *ngIf="propertyScheme.active && propertyScheme.visible && propertyScheme.displayMode === 'LIST' && product.properties[propertyScheme.code]">
                <span *ngIf="propertyScheme.name"><strong>{{ propertyScheme.name }}</strong> : </span><span [innerHTML]="product.properties[propertyScheme.code]?.join(', ')"></span>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="product.properties">
          <mat-tab-group class="mt30" color="accent" (selectedTabChange)="selectTab($event)">
            <ng-container *ngFor="let propertyScheme of getProductScheme()?.productPropertySchemes">
              <ng-container *ngIf="propertyScheme.active && propertyScheme.visible && propertyScheme.displayMode === 'TAB' && product.properties[propertyScheme.code]">
                <mat-tab [label]="propertyScheme.name" class="mt10 mb10">
                  <span [innerHTML]="product.properties[propertyScheme.code].join(', ')"></span>
                </mat-tab>
              </ng-container>
            </ng-container>
          </mat-tab-group>
        </ng-container>

        <div class="row actions">
          <div class="col-xs-4 col-sm-3 col-md-3 pt5"><strong class="price" *ngIf="product.price" [innerHTML]="product.price | price"></strong></div>
          <div class="col-xs-8 col-sm-9 col-md-9 text-right">
            <ng-container *ngIf="!isAlreadyInTransaction">
<!--              <button mat-flat-button *ngIf="sharedVariablesService.config.ERESA" (click)="openEResa()"><mat-icon svgIcon="package"></mat-icon> {{ 'PRODUCT.BOOK_IN_STORE' | translate }}</button>-->
              <button mat-flat-button color="primary" class="ml10 mt5" [disabled]="loading || (product.stock === 0)"
                      [class.spinner]="loading" attr.aria-label="Ajouter au panier"
                      (click)="product.hasOptions() ? askForOptions(product) : addToCart(product)"><mat-icon svgIcon="shopping-cart-add"></mat-icon> Ajouter au panier</button>
            </ng-container>
            <ng-container *ngIf="isAlreadyInTransaction">
              <ng-container *ngIf="product.hasOptions(); else noOption">
                <div class="primary">Ajouté</div>
              </ng-container>
              <ng-template #noOption>
                <button mat-icon-button color="primary" (click)="decreaseQuantity(product)" attr.aria-label="Diminuer la quantité"><mat-icon>remove_circle</mat-icon></button>
                <strong>{{ sharedVariablesService.transaction?.itemsAsObject[product.id] ? sharedVariablesService.transaction?.itemsAsObject[product.id][product.ean] : 0 }}</strong>
                <button mat-icon-button color="primary" (click)="addToCart(product)" attr.aria-label="Augmenter la quantité"
                        [disabled]="sharedVariablesService.transaction?.itemsAsObject[product.id] && product.maxQuantityInTransaction && product.maxQuantityInTransaction <= sharedVariablesService.transaction?.itemsAsObject[product.id][product.ean]"><mat-icon>add_circle</mat-icon></button>
              </ng-template>
            </ng-container>
            <div class="unavailable" *ngIf="product.stock === 0">
              <div class="pt5"><span>Actuellement indisponible</span></div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</article>

<!--RELATED PRODUCTS-->
<div *ngIf="product?.relatedProducts.length > 0">
  <div class="container mb20 mt20">
    <h2>PRODUITS ASSOCIÉS</h2>

    <div class="row">
      <div class="col-xs-6 col-md-3" *ngFor="let relatedProduct of product.relatedProducts">
        <app-product-item-small [productId]="relatedProduct.id"></app-product-item-small>
      </div>

    </div>
  </div>
</div>
<!--<app-cart-fab></app-cart-fab>-->
