import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Range {
  code: string;
  name: string;

  constructor(code?: string, name?: string) {
    this.code = code;
    this.name = name;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RangeAdapter implements Adapter<Range> {

  adapt(item: any): Range {
    return new Range(
      item.code,
      item.name
    );
  }
}
