import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {GoogleAnalyticsService} from '../../lib/services/google-analytics.service';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {TransactionService} from '../../lib/services/transaction.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.scss']
})
export class CartPanelComponent {
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public sharedVariablesService: SharedVariablesService,
              private transactionService: TransactionService,
              private dialog: MatDialog,
              private googleAnalyticsService: GoogleAnalyticsService,
              private ngxLoaderService: NgxUiLoaderService) {
  }

  closePanel() {
    this.toggle.emit(false);
  }

  emptyCart() {
    this.sharedVariablesService.transaction.clearItems();
    this.ngxLoaderService.start('createOrUpdateTransaction');
    lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
      this.sharedVariablesService.transaction = transaction;
      this.ngxLoaderService.stop('createOrUpdateTransaction');
    }, err => {
      this.ngxLoaderService.stop('createOrUpdateTransaction');
    });

    // Send GA event
    this.googleAnalyticsService.eventEmitter('clean_cart', {origin: 'shopping_cart'});
  }

  beginCheckout() {
    this.closePanel();
    this.googleAnalyticsService.beginCheckout(this.sharedVariablesService.transaction);
  }

  changeCollect() {
    // Send GA event
    this.googleAnalyticsService.eventEmitter('modify_collect_details');
  }
}
