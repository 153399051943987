import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class WithdrawalDate {
  date: Date;
  hours: string[] = [];

  constructor(date: Date, hours: string[]) {
    this.date = date;
    this.hours = hours;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WithdrawalDateAdapter implements Adapter<WithdrawalDate> {
  adapt(item: any): WithdrawalDate {
    return new WithdrawalDate(
      item.date ? new Date(item.date) : undefined,
      item.hours ? item.hours : []
    );
  }
}
