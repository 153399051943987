import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Product, ProductAdapter } from './Product';

export class ProductSelection {
  products: Product[] = [];
  productIds: string[] = [];
  categories: string[] = [] ;
  categoryIds: string[] = [];

  constructor(products?: Product[], productIds?: string[], categories?: string[], categoryIds?: string[]) {
    this.products = products;
    this.productIds = productIds;
    this.categories = categories;
    this.categoryIds = categoryIds;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductSelectionAdapter implements Adapter<ProductSelection> {

  adapt(item: any): ProductSelection {
    const productAdapter: ProductAdapter = new ProductAdapter();

    return new ProductSelection(
      item.products ? item.products.map(e => productAdapter.adapt(e)) : [],
      item.productIds,
      item.categories,
      item.categoryIds
    );
  }
}
