import { Injectable } from '@angular/core';
import { Address, AddressAdapter } from './Address';
import { Customer, CustomerAdapter } from './Customer';
import { Adapter } from '../interfaces/adapter.interface';
import { OrderLine, OrderLineAdapter } from './OrderLine';
import { OrderStatusEvent, OrderStatusEventAdapter } from './OrderStatusEvent';
import { SaleChannel, SaleChannelAdapter } from './SaleChannel';

export class Order {
  id: string;
  orderDate: Date;
  type: OrderType;
  customer: Customer;
  address: Address;
  orderStatus: OrderStatus;
  events: OrderStatusEvent[];
  expectedWithdrawalDate: Date;
  expectedWithdrawalTime: string;
  expectedDeliveryDate: Date;
  expectedDeliveryTimeFrom: string;
  expectedDeliveryTimeTo: string;
  expectedTreatmentDate: Date;
  originOrganisationId: string;
  treatmentOrganisationId: string;
  eligibleTreatmentOrganisationIds: string[];
  saleChannelCode: string;
  saleChannel: SaleChannel;
  creationDate: Date;
  modificationDate: Date;
  lines: OrderLine[];
  transactionId: string;
  childrenTransactions: ChildrenTransaction[] = [];
  tenders: Tender[] = [];
  totalAmount: number;
  totalPurchasingAmount: number;
  totalAdvanceAmount: number;
  totalLeftToPayAmount: number;
  withdrawalDate: Date;
  deliveryDate: Date;
  classificationId: string;
  properties: {[name: string]: any};
  onAlert: boolean = false;
  alertMessages: string[] = [];
  supplyOrderIds: string[] = [];
  trackingNumber: string;
  totalDeliveryCostAmount: number;
  totalDeliveryCostGrossAmount: number;
  externalBusinessId: string;

  constructor(id?: string, orderDate?: Date, type?: OrderType, customer?: Customer, address?: Address, orderStatus?: OrderStatus,
              events?: OrderStatusEvent[], expectedWithdrawalDate?: Date, expectedWithdrawalTime?: string, expectedDeliveryDate?: Date,
              expectedDeliveryTimeFrom?: string, expectedDeliveryTimeTo?: string,
              expectedTreatmentDate?: Date, originOrganisationId?: string, treatmentOrganisationId?: string,
              eligibleTreatmentOrganisationIds?: string[], saleChannelCode?: string, saleChannel?: SaleChannel, creationDate?: Date,
              modificationDate?: Date, lines?: OrderLine[], transactionId?: string, childrenTransactions?: ChildrenTransaction[], tenders?: Tender[],
              totalAmount?: number, totalPurchasingAmount?: number, totalAdvanceAmount?: number, totalLeftToPayAmount?: number, withdrawalDate?: Date,
              deliveryDate?: Date, classificationId?: string, onAlert: boolean = false, alertMessages?: string[], supplyOrderIds?: string[],
              properties?: { [p: string]: any }, trackingNumber?: string, totalDeliveryCostAmount?: number, totalDeliveryCostGrossAmount?: number,
              externalBusinessId?: string) {
    this.id = id;
    this.orderDate = orderDate;
    this.type = type;
    this.customer = customer;
    this.address = address;
    this.orderStatus = orderStatus;
    this.events = events;
    this.expectedWithdrawalDate = expectedWithdrawalDate;
    this.expectedWithdrawalTime = expectedWithdrawalTime;
    this.expectedDeliveryDate = expectedDeliveryDate;
    this.expectedDeliveryTimeFrom = expectedDeliveryTimeFrom;
    this.expectedDeliveryTimeTo = expectedDeliveryTimeTo;
    this.expectedTreatmentDate = expectedTreatmentDate;
    this.originOrganisationId = originOrganisationId;
    this.treatmentOrganisationId = treatmentOrganisationId;
    this.eligibleTreatmentOrganisationIds = eligibleTreatmentOrganisationIds;
    this.saleChannelCode = saleChannelCode;
    this.saleChannel = saleChannel;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.lines = lines;
    this.transactionId = transactionId;
    this.childrenTransactions = childrenTransactions;
    this.tenders = tenders;
    this.totalAmount = totalAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.totalAdvanceAmount = totalAdvanceAmount;
    this.totalLeftToPayAmount = totalLeftToPayAmount;
    this.withdrawalDate = withdrawalDate;
    this.deliveryDate = deliveryDate;
    this.classificationId = classificationId;
    this.onAlert = onAlert;
    this.alertMessages = alertMessages;
    this.supplyOrderIds = supplyOrderIds;
    this.properties = properties;
    this.trackingNumber = trackingNumber;
    this.totalDeliveryCostAmount = totalDeliveryCostAmount;
    this.totalDeliveryCostGrossAmount = totalDeliveryCostGrossAmount;
    this.externalBusinessId = externalBusinessId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderAdapter implements Adapter<Order> {
  adapt(item: any): Order {
    const customerAdapter: CustomerAdapter = new CustomerAdapter();
    const addressAdapter: AddressAdapter = new AddressAdapter();
    const orderStatusEventAdapter: OrderStatusEventAdapter = new OrderStatusEventAdapter();
    const saleChannelAdapter: SaleChannelAdapter = new SaleChannelAdapter();
    const orderLineAdapter: OrderLineAdapter = new OrderLineAdapter();
    return new Order(
      item.id,
      item.orderDate ? new Date(item.orderDate.toString()) : undefined,
      item.type,
      item.customer ? customerAdapter.adapt(item.customer) : undefined,
      item.address ? addressAdapter.adapt(item.address) : undefined,
      item.orderStatus,
      item.events ? item.events.map((e) => {
        e = orderStatusEventAdapter.adapt(e);
        return e;
      }) : undefined,
      item.expectedWithdrawalDate ? new Date(item.expectedWithdrawalDate.toString()) : undefined,
      item.expectedWithdrawalTime,
      item.expectedDeliveryDate ? new Date(item.expectedDeliveryDate.toString()) : undefined,
      item.expectedDeliveryTimeFrom,
      item.expectedDeliveryTimeTo,
      item.expectedTreatmentDate ? new Date(item.expectedTreatmentDate.toString()) : undefined,
      item.originOrganisationId,
      item.treatmentOrganisationId,
      item.eligibleTreatmentOrganisationIds,
      item.saleChannelCode,
      item.saleChannel ? saleChannelAdapter.adapt(item.saleChannel) : undefined,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.lines ? item.lines.map((e) => {
        e = orderLineAdapter.adapt(e);
        return e;
      }) : undefined,
      item.transactionId,
      item.childrenTransactions,
      item.tenders,
      item.totalAmount,
      item.totalPurchasingAmount,
      item.totalAdvanceAmount,
      item.totalLeftToPayAmount,
      item.withdrawalDate ? new Date(item.withdrawalDate.toString()) : undefined,
      item.deliveryDate ? new Date(item.deliveryDate.toString()) : undefined,
      item.classificationId,
      item.onAlert,
      item.alertMessages ? item.alertMessages : [],
      item.supplyOrderIds ? item.supplyOrderIds : [],
      item.properties,
      item.trackingNumber,
      item.totalDeliveryCostAmount,
      item.totalDeliveryCostGrossAmount,
      item.externalBusinessId
    );
  }

  prepare(object: Order): any {
    const target = {};
    Object.assign(target, object);
    target['saleChannelCode'] = object.saleChannel.code;
    delete target['saleChannel'];

    return target;
  }
}

export enum OrderStatus  {
  WAITING_FOR_PICKING = 'WAITING_FOR_PICKING',
  TO_PREPARE = 'TO_PREPARE',
  IN_PREPARATION = 'IN_PREPARATION',
  IN_RESERVATION = 'IN_RESERVATION',
  IN_SUPPLY = 'IN_SUPPLY',
  WAITING_FOR_WITHDRAWAL = 'WAITING_FOR_WITHDRAWAL',
  DISCARDED = 'DISCARDED',
  WITHDRAWN = 'WITHDRAWN',
  TO_SEND = 'TO_SEND',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum OrderType {
  WITHDRAWAL = 'WITHDRAWAL',
  DELIVERY = 'DELIVERY',
  SUPPLY = 'SUPPLY',
  MULTIPLE_WITHDRAWAL = 'MULTIPLE_WITHDRAWAL',
  MULTIPLE_DELIVERY = 'MULTIPLE_DELIVERY',
}

export interface ChildrenTransaction {
  transactionId: string;
  type: string;
  date: Date;
}

export interface Tender {
  amount: number;
  type: string;
  date: Date;
}
