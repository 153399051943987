import { Injectable } from '@angular/core';
import { AnalyticsEcommerceItem } from '../model/AnalyticsEcommerceItem';
import { Item } from '../model/Item';
import { Product } from '../model/Product';
import { Transaction } from '../model/Transaction';
import { SharedVariablesService } from './shared-variables.service';

declare let gtag: Function;
declare global {
  interface Window { dataLayer: any[]; }
}

class Products {
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  isProd: boolean = false;

  constructor(private sharedVariablesService: SharedVariablesService) {
    this.isProd = true;//(sharedVariablesService.env === 'PROD');
  }

  /**
   * Emits an Google Analytics event.
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
   * @param action
   * @param parameters
   */
  public eventEmitter(
    action: string,
    parameters: any = {}) {

    // Measure product views / impressions
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

    let data = parameters;
    data['event'] = action;

    window.dataLayer.push(data);
  }

  /**
   * Emits a search event.
   * @see https://developers.google.com/gtagjs/reference/ga4-events#search
   * @param term
   */
  public searchEvent(term) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      gtag('event', 'search', {
        search_term: term
      });
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_productitem_list_viewsimpressions
   * @param items
   * @param listName
   */
  public viewItemList(items: Product[] = [], listName: string) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let data = {
        'event': 'view_item_list',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': []
        }
      };
      items.forEach((item, index) => {
        data.ecommerce.items.push(this.productToEcommerceItem(item, listName, listName, listName, index));
      });

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_productitem_list_clicks
   * @param item
   * @param listName
   */
  public selectItem(item: Product, listName: string) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let data = {
        'event': 'select_item',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': [
            this.productToEcommerceItem(item, listName, listName, listName)
          ]
        }
      };

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_viewsimpressions_of_productitem_details
   * @param item
   */
  public viewItem(item: Product) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let data = {
        'event': 'view_item',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': [
            this.productToEcommerceItem(item)
          ]
        }
      };

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
   * @param item
   * @param listName
   */
  public addItemToCart(item: Product|Item, listName: string) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let i;
      if (item instanceof Product) {
        i = this.productToEcommerceItem(item, listName, listName, listName);
      } else {
        i = this.transactionItemToEcommerceItem(item, listName, listName);
      }
      let data = {
        'event': 'add_to_cart',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': [
            i
          ]
        }
      };

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
   * @param item
   * @param listName
   */
  public removeItemFromCart(item: Product|Item, listName: string) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let i;
      if (item instanceof Product) {
        i = this.productToEcommerceItem(item, listName, listName, listName);
      } else {
        i = this.transactionItemToEcommerceItem(item, listName, listName);
      }
      let data = {
        'event': 'remove_from_cart',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': [
            i
          ]
        }
      };

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_a_checkout
   * @param transaction
   */
  public beginCheckout(transaction: Transaction) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let data = {
        'event': 'begin_checkout',
        'ecommerce': {
          'currency': this.sharedVariablesService.organisation.currency,
          'items': []
        }
      };

      transaction.items.forEach((item, index) => {
        data.ecommerce.items.push(this.transactionItemToEcommerceItem(item));
      });

      window.dataLayer.push(data);
    }
  }

  /**
   * @see https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
   * @param transaction
   */
  public purchase(transaction: Transaction) {
    if (this.sharedVariablesService.googleAnalyticsConsent && this.isProd) {
      // Measure product views / impressions
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      let data = {
        'event': 'purchase',
        'ecommerce': {
          'transaction_id': transaction.id,
          'affiliation': 'B2BWEB',
          'value': transaction.totalAmount,
          'shipping': 0,
          'currency': this.sharedVariablesService.organisation.currency,
          'items': []
        }
      };

      transaction.items.forEach((item, index) => {
        data.ecommerce.items.push(this.transactionItemToEcommerceItem(item));
      });

      window.dataLayer.push(data);
    }
  }

  private productToEcommerceItem(product: Product,
                                 category: string = undefined,
                                 listName: string = undefined,
                                 listId: string = undefined,
                                 index: number = undefined): AnalyticsEcommerceItem {
    return new AnalyticsEcommerceItem(
      product.name,
      product.id,
      product.price,
      this.sharedVariablesService.instance,
      category,
      index,
      listName,
      listId
    );
  }

  private transactionItemToEcommerceItem(item: Item,
                                         listName: string = undefined,
                                         listId: string = undefined): AnalyticsEcommerceItem {
    return new AnalyticsEcommerceItem(
      item.name,
      item.productId,
      item.totalAmount,
      this.sharedVariablesService.instance,
      undefined,
      undefined,
      listName,
      listId,
      item.quantity
    );
  }
}
