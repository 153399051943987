import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Customer, CustomerAdapter} from '../model/Customer';
import {ApiService} from './api.service';

@Injectable()
export class CustomerService extends ApiService{
  readonly CUSTOMER_API_URL: string = 'customers';

  constructor(private http: HttpClient,
              private adapter: CustomerAdapter) {
    super();
  }

  /**
   *  Find Customer by email
   * @param email
   */
  getCustomerByEmail(email: string): Observable<Customer> {
    let params = new HttpParams();
    params = params.set('email', email);

    return this.http.get<Customer>(this.baseUriNoAuthKey + this.CUSTOMER_API_URL, {params})
      .pipe(map((item: any) => this.adapter.adapt(item)));
  }

  /**
   * Get full customer with addresses
   * @param customerId
   */
  getCustomer(customerId: string): Observable<Customer> {
    const params = new HttpParams();
    return this.http.get(this.baseUriNoAuthKey + this.CUSTOMER_API_URL + '/' + customerId.replace(/\+/gi, '%2B'), {params})
      .pipe(map((item: any) => this.adapter.adapt(item))
      );
  }

  // /**
  //  * Update a customer
  //  * @param customer
  //  */
  // updateCustomer(customer: Customer) {
  //   const params = new HttpParams();
  //   return this.http.post(this.CUSTOMER_API_URL + '/' + customer.id, customer, {params})
  //     .map((item: any) => this.adapter.adapt(item)
  //     );
  // }
}
