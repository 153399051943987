<mat-accordion *ngIf="orders">
  <mat-expansion-panel *ngFor="let order of orders" class="orders">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong class="primary">{{ order.creationDate | date: 'dd/MM/yyyy' }}</strong>
      </mat-panel-title>
      <mat-panel-description>
        <div class="hidden-xs">N° {{ order.id }}</div>
        <div class="hidden-xs">{{ humanReadableStatus(order.orderStatus) }}</div>
        <div class="hidden-xs">{{ order.lines.length }} article(s)</div>
        <div><strong class="accent">{{ order.totalAmount | price }}</strong></div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="visible-xs">N° {{ order.externalBusinessId ? order.externalBusinessId : order.id }}</div>
    <div class="visible-xs">{{ humanReadableStatus(order.orderStatus) }}</div>
    <div class="visible-xs">{{ order.lines.length }} article(s)</div>

    <div class="articles">
      <div class="item" *ngFor="let line of order.lines; let i = index;">
        <div class="item-image" *ngIf="line.defaultImage">
          <img [src]="line.defaultImage" [alt]="line.name">
        </div>
        <div class="item-description">
          <div class="item-name">{{ line.name }}</div>
          <div *ngIf="line.options" class="item-options">
            <div class="option-list-item" *ngFor="let productOptionValue of line.options"><strong>{{ productOptionValue.productOptionName }}</strong><span *ngIf="productOptionValue.productOptionPrice > 0"> ({{ productOptionValue.productOptionPrice | price }})</span>
              <span *ngIf="productOptionValue.productOptionValueName"> : {{ productOptionValue.productOptionValueName }} <span *ngIf="productOptionValue.productOptionValueName"> ({{ productOptionValue.productOptionValuePrice | price }})</span></span>
            </div>
          </div>
          <div class="item-quantity">Quantité : {{ line.quantityWithCoefficient }} {{ line.salesUnitLabel }}</div>
        </div>
        <div class="item-price">
          <span>{{ line.totalAmount | price }}</span>
        </div>
      </div>
      <div class="item">
        <div class="item-image">
          <mat-icon color="primary" class="align-middle mr10" svgIcon="delivery-truck-2"></mat-icon>
        </div>
        <div class="item-description">
          Frais de livraison Chronopost
          <span *ngIf="order.trackingNumber">(N° suivi : {{ order.trackingNumber }})</span>
        </div>
        <div class="item-price">
          <span>{{ order.totalDeliveryCostAmount | price }}</span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
