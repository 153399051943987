import {Component, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ProductDeclinationImage} from '../../lib/model/ProductDeclination';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {Title} from '@angular/platform-browser';
import {Product} from '../../lib/model/Product';
import { TagsService } from '../../lib/services/tags.service';
import {ProductItemComponent} from '../../partials/product-item/product-item.component';
import {TransactionService} from '../../lib/services/transaction.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../lib/services/product.service';
import {MatDialog} from '@angular/material/dialog';
import {GoogleAnalyticsService} from '../../lib/services/google-analytics.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends ProductItemComponent implements OnInit {
  public product: Product;
  public productImage: ProductDeclinationImage;

  constructor(sharedVariablesService: SharedVariablesService,
              titleService: Title,
              transactionService: TransactionService,
              ngxLoaderService: NgxUiLoaderService,
              translateService: TranslateService,
              productService: ProductService,
              tagService: TagsService,
              dialog: MatDialog,
              googleAnalyticsService: GoogleAnalyticsService,
              snackbar: MatSnackBar,
              private route: ActivatedRoute,
              private location: Location) {
    super(sharedVariablesService,
      titleService,
      transactionService,
      ngxLoaderService,
      translateService,
      productService,
      tagService,
      dialog,
      googleAnalyticsService,
      snackbar);
  }

  ngOnInit() {
    const ean = this.route.snapshot.paramMap.get('ean');

    if (!ProductComponent.productScheme) {
      lastValueFrom(this.productService.getProductScheme()).then((productScheme) => {
        ProductComponent.productScheme = productScheme;
      });
    }

    lastValueFrom(this.productService.getProduct(ean)).then((product) => {
      this.product = product;
      this.productImage = this.product.getDefaultImage();

      // Send to GA
      this.googleAnalyticsService.viewItem(product);

      // Set page title
      this.titleService.setTitle(this.sharedVariablesService.saleChannel.title + ' - ' + product.name);

      super.ngOnInit();
    });
  }

  goBack(): void {
    // Send GA event
    this.googleAnalyticsService.eventEmitter('back_to_catalogue', { origin: 'product', productName: this.product.name });

    this.location.back();
  }

  magnify(key: string): string {
    if (key) {
      let output = '';
      const len = key.length;
      let char;

      for (let i = 0; i < len; i++) {
        char = key.charAt(i);

        if (i === 0) {
          output += char.toUpperCase();
        } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
          output += ' ' + char;
        } else if (char === '-' || char === '_') {
          output += ' ';
        } else {
          output += char;
        }
      }

      return output;
    }
    return key;
  }

  selectImage(image: ProductDeclinationImage, i: number): void {
    this.productImage = image;

    // Send GA event
    this.googleAnalyticsService.eventEmitter('click_other_photo', { productName: this.product.name, photoNumber: i });
  }

  selectTab($event: any) {
    // Send GA event
    this.googleAnalyticsService.eventEmitter('click_product_info_tab', { productName: this.product.name, tabName: $event.tab.textLabel });
  }
}
