import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderStatus } from '../../lib/model/Order';

@Component({
  selector: 'app-customer-orders',
  templateUrl: './customer-orders.component.html',
  styleUrls: ['./customer-orders.component.scss']
})
export class CustomerOrdersComponent implements OnInit {
  @Input() orders: Order[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  humanReadableStatus(orderStatus: OrderStatus) {
    if (orderStatus === OrderStatus.CANCELED) {
      return 'Annulée';
    } else if (orderStatus === OrderStatus.DELIVERED) {
      return 'Livrée';
    } else if (orderStatus === OrderStatus.IN_PREPARATION) {
      return 'Préparation en cours';
    } else if (orderStatus === OrderStatus.SENT) {
      return 'Envoyée';
    } else if (orderStatus === OrderStatus.TO_PREPARE) {
      return 'À préparer';
    } else if (orderStatus === OrderStatus.TO_SEND) {
      return 'À envoyer';
    } else {
    return '';
    }
  }
}
