<div class="login-component">
  <div class="text-center card-container">
    <mat-card class="login-card">
      <h2 class="mt30">BIENVENUE</h2>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!customer && showForm">
          <mat-form-field class="full-width">
            <mat-label>Email / Identifiant</mat-label>
            <input matInput placeholder="Email / Identifiant" formControlName="login">
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Mot de passe</mat-label>
            <input type="password" matInput placeholder="Mot de passe" formControlName="password"/>
          </mat-form-field>
          <button type="submit" mat-raised-button color="primary" [class.spinner]="loading"
                  [disabled]="loading">CONNEXION
          </button>
      </form>
      <div *ngIf="oAuth2Clients">
        <div class="text-center small grey mt20 mb20" *ngIf="showForm">OU</div>
        <a mat-button mat-stroked-button class="oauth-button mb20" *ngFor="let oAuth2Client of oAuth2Clients"
           href="{{oAuthBaseUrl}}/api/{{oAuth2Client.oauthRoute}}">
          <img src="/assets/images/oauth2-{{oAuth2Client.registrationId}}.png" alt="{{oAuth2Client.registrationId}}">
          Se connecter avec {{oAuth2Client.clientName}}</a>
      </div>

      <div class="row card-footer mt20">
        <div class="col-sm-12 col-md-4 text-left ml5">
          <img class="footer-logo" src="/assets/images/logo_keyneo.png" alt="keyneo">
        </div>
        <div class="col-sm-12 col-md-4">
<!--          <button type="button" class="small" mat-button (click)="openResetPasswordDialog()">Mot de passe oublié ?</button>-->
        </div>
        <div class="col-sm-12 col-md-4 text-right mr5">
          <span class="footer-version">Version {{version}}</span>
        </div>
      </div>

    </mat-card>
    <div class="user-login">
      <button type="button" class="mb10 mr10 small" mat-raised-button (click)="showForm = !showForm">ACCÈS ADMINISTRATEUR</button>
    </div>
  </div>
</div>
