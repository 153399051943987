import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category, CategoryAdapter } from '../model/Category';
import { SaleChannel } from '../model/SaleChannel';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends ApiService {

  constructor(private http: HttpClient, private adapter: CategoryAdapter) {
    super();
  }

  /**
   * Returns categories tree for a sale channel.
   * @param saleChannel
   * @param level
   */
  public getCategoriesBySaleChannel(saleChannel: SaleChannel, level: number = 1): Observable<Category[]> {
    let params = new HttpParams();
    params = params.set('level', level.toString())
      .set('saleChannelCode', 'B2BWEB');

    return this.http.get<Category[]>(this.baseUri + 'categories/root', {params})
      .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
  }

  /**
   * Returns categories tree.
   * @param categoryCode
   * @param saleChannel
   * @param level
   */
  public getCategoriesTree(categoryCode: string, saleChannel: SaleChannel, level: number = 1): Observable<Category> {
    let params = new HttpParams();
    params = params.set('level', level.toString())
      .set('saleChannelCode', 'B2BWEB');

    return this.http.get<Category>(this.baseUri + 'categories/tree/' + categoryCode, {params})
      .pipe(map(item => this.adapter.adapt(item)));
  }
}
