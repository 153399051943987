import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Advertisement, AdvertisementAdapter } from '../model/Advertisement';
import { ApiService } from './api.service';

@Injectable()
export class AdvertisementService extends ApiService {

  constructor(private http: HttpClient,
              private advertisementAdapter: AdvertisementAdapter) {
    super();
  }

  public getAdvertisements(saleChannelCode: string, slot: string): Observable<Advertisement[]> {
    let params = new HttpParams();
    params = params.set('saleChannelCodes', saleChannelCode);
    params = params.set('slots', slot);
    params = params.set('active', 'true');

    return this.http.get<Advertisement[]>(this.baseUri + 'display', {params}).pipe(
      map((data: any[]) => data.map(item => this.advertisementAdapter.adapt(item)))
    );
  }
}
