import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Article, ArticleAdapter } from './Article';
import { LocationFormat, LocationFormatAdapter } from './LocationFormat';
import { LocationStatusEvent, LocationStatusEventAdapter } from './LocationStatusEvent';
import { Product, ProductAdapter } from './Product';

/**
 * Called Location for backEnd Kotlin
 */
export class StorageLocation {
  id: string;
  businessId: string;
  locationGroupId: string;
  organisationId: string;
  status: string;
  format: string;
  electronicTagId: string;
  type: string;
  targetContent: LocationTargetContent;
  localization: LocationLocalization;

  events: LocationStatusEvent[];
  articles: Article[];
  articlesCount: number;
  formatDetail: LocationFormat;

  constructor(id?: string, businessId?: string, locationGroupId?: string, organisationId?: string, status?: string, format?: string,
              electronicTagId?: string, type?: string, targetContent?: LocationTargetContent, localization?: LocationLocalization,
              events?: LocationStatusEvent[], articles?: Article[], articlesCount?: number, formatDetail?: LocationFormat) {
    this.id = id;
    this.businessId = businessId;
    this.locationGroupId = locationGroupId;
    this.organisationId = organisationId;
    this.status = status;
    this.format = format;
    this.electronicTagId = electronicTagId;
    this.type = type;
    this.targetContent = targetContent;
    this.localization = localization;
    this.events = events;
    this.articles = articles;
    this.articlesCount = articlesCount;
    this.formatDetail = formatDetail;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StorageLocationAdapter implements Adapter<StorageLocation> {
  adapt(item: any): StorageLocation {
    const locationStatusEventAdapter: LocationStatusEventAdapter = new LocationStatusEventAdapter();
    const locationTargetContentAdapter: LocationTargetContentAdapter = new LocationTargetContentAdapter();
    const locationLocalizationAdapter: LocationLocalizationAdapter = new LocationLocalizationAdapter();
    const articleAdapter: ArticleAdapter = new ArticleAdapter();
    const locationFormatAdapter: LocationFormatAdapter = new LocationFormatAdapter();

    return new StorageLocation(
      item.id,
      item.businessId,
      item.locationGroupId,
      item.organisationId,
      item.status,
      item.format,
      item.electronicTagId,
      item.type,
      item.targetContent ? locationTargetContentAdapter.adapt(item.targetContent) : undefined,
      item.localization ? locationLocalizationAdapter.adapt(item.localization) : undefined,
      item.events ? item.events.map(e => locationStatusEventAdapter.adapt(e)) : [],
      item.articles ? item.articles.map(e => articleAdapter.adapt(e)) : [],
      item.articlesCount,
      item.formatDetail ? locationFormatAdapter.adapt(item.formatDetail) : undefined
    );
  }
}

export class LocationTargetContent {
  productId: string;
  minQuantity: number;
  maxQuantity: number;
  product: Product;
  minQuantityWithCoefficient: number;
  maxQuantityWithCoefficient: number;

  constructor(productId?: string, minQuantity?: number, maxQuantity?: number, product?: Product, minQuantityWithCoefficient?: number,
              maxQuantityWithCoefficient?: number) {
    this.productId = productId;
    this.minQuantity = minQuantity;
    this.maxQuantity = maxQuantity;
    this.product = product;
    this.minQuantityWithCoefficient = minQuantityWithCoefficient;
    this.maxQuantityWithCoefficient = maxQuantityWithCoefficient;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocationTargetContentAdapter implements Adapter<LocationTargetContent> {
  adapt(item: any): LocationTargetContent {
    const productAdapter: ProductAdapter = new ProductAdapter();

    return new LocationTargetContent(
      item.productId,
      item.minQuantity,
      item.maxQuantity,
      item.product ? productAdapter.adapt(item.product) : undefined,
      item.minQuantityWithCoefficient,
      item.maxQuantityWithCoefficient
    );
  }
}

export class LocationLocalization {
  alley: string;
  column: string;
  line: string;

  constructor(alley?: string, column?: string, line?: string) {
    this.alley = alley;
    this.column = column;
    this.line = line;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocationLocalizationAdapter implements Adapter<LocationLocalization> {
  adapt(item: any): LocationLocalization {

    return new LocationLocalization(
      item.alley,
      item.column,
      item.line
    );
  }
}
