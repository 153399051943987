import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Item } from '../../lib/model/Item';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() item: Item;
  @Input() index: number;
  @Input() readonly = false;
  public isInPack = false;

  constructor(public sharedVariablesService: SharedVariablesService,
              private transactionService: TransactionService,
              private ngxLoaderService: NgxUiLoaderService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private googleAnalyticsService: GoogleAnalyticsService,
              private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.isInPack = this.item.hasPackApplied();
  }

  increaseQuantity() {
    const self = this;
    this.ngxLoaderService.start('createOrUpdateTransaction');

    this.googleAnalyticsService.addItemToCart(this.item, 'shopping_cart');

    if (this.item.hasOptions()) {
      this.sharedVariablesService.transaction.itemsWithOptions[this.index].quantity++;
    } else {
      this.sharedVariablesService.transaction.updateProductInTransaction(this.item,
        this.sharedVariablesService.transaction.itemsAsObject[this.item.productId][this.item.ean] + 1);
    }
    lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
      this.sharedVariablesService.transaction = transaction;

      this.ngxLoaderService.stop('createOrUpdateTransaction');

      self.snackbar.open('Panier mis à jour avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, (error) => {
      if (this.item.hasOptions()) {
        this.sharedVariablesService.transaction.itemsWithOptions[this.index].quantity--;
      } else {
        this.sharedVariablesService.transaction.updateProductInTransaction(this.item,
          this.sharedVariablesService.transaction.itemsAsObject[this.item.productId][this.item.ean] - 1);
      }
      this.ngxLoaderService.stop('createOrUpdateTransaction');
    });
  }

  decreaseQuantity() {
    const self = this;
    this.ngxLoaderService.start('createOrUpdateTransaction');

    this.googleAnalyticsService.removeItemFromCart(this.item, 'shopping_cart');

    if (this.item.hasOptions()) {
      this.sharedVariablesService.transaction.itemsWithOptions[this.index].quantity--;
    } else {
      this.sharedVariablesService.transaction.updateProductInTransaction(this.item,
        this.sharedVariablesService.transaction.itemsAsObject[this.item.productId][this.item.ean] - 1);
    }
    lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
      this.sharedVariablesService.transaction = transaction;

      this.ngxLoaderService.stop('createOrUpdateTransaction');

      self.snackbar.open('Panier mis à jour avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, err => {
      this.ngxLoaderService.stop('createOrUpdateTransaction');
    });
  }

  deleteFromCart() {
    const self = this;
    this.ngxLoaderService.start('createOrUpdateTransaction');

    if (this.item.hasOptions()) {
      this.sharedVariablesService.transaction.itemsWithOptions[this.index].quantity = 0;
    } else {
      this.sharedVariablesService.transaction.updateProductInTransaction(this.item, 0);
    }
    lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
      this.sharedVariablesService.transaction = transaction;

      this.ngxLoaderService.stop('createOrUpdateTransaction');

      self.snackbar.open('Panier mis à jour avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, err => {
      this.ngxLoaderService.stop('createOrUpdateTransaction');
    });
  }
}
