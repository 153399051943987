import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Advertisement } from '../../lib/model/Advertisement';
import { Appointment } from '../../lib/model/Appointment';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import {Delivery} from '../../lib/model/Delivery';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class CartComponent implements OnInit {
  public advertisement: Advertisement;
  @ViewChild('cartStepper', {static: true}) cartStepper: MatStepper;
  showDeliveryFrame = false;
  appointment: Appointment;
  transactionCompleted = false;
  meetUrl: SafeUrl;
  canHaveAppointment = false;

  constructor(public sharedVariablesService: SharedVariablesService,
              private location: Location,
              public router: Router,
              private ngxLoaderService: NgxUiLoaderService,
              private transactionService: TransactionService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.showDeliveryFrame = false;
    this.canHaveAppointment = this.sharedVariablesService.transaction?.appointmentMakingDirective !== 'NONE';

    if (this.sharedVariablesService.transaction.delivery && this.sharedVariablesService.transaction.delivery.appointment) {
      this.appointment = this.sharedVariablesService.transaction.delivery.appointment;
    }

    window.addEventListener('message', (event) => {
      if (event.data && (typeof event.data === 'string')) {
        if (!this.sharedVariablesService?.transaction?.delivery) {
          this.sharedVariablesService.transaction.delivery = new Delivery();
        }
        this.sharedVariablesService.transaction.delivery.appointmentId = event.data;
        lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
          this.sharedVariablesService.transaction = transaction;
          this.appointment = transaction.delivery.appointment;
          this.cartStepper.next();
        });
      }
    });

    // We set iframe URL
    let url = (this.sharedVariablesService.env === 'PROD') ? 'https://giphar.meet.keyneosoft.io/' : 'https://giphar.meet.staging.keyneosoft.io/';
    url += '?id=' + this.sharedVariablesService.customer.id;
    this.meetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    if (this.sharedVariablesService.transaction.appointmentMakingDirective === 'MANDATORY') {
      this.showDelivery();
    }
  }

  validateOrder() {
    this.ngxLoaderService.start();
    lastValueFrom(this.transactionService.updateTransactionStatus(this.sharedVariablesService.transaction, 'VALIDATED')).then((transaction) => {
      this.ngxLoaderService.stop();
      this.transactionCompleted = true;
      this.sharedVariablesService.clear();
    });
  }

  showDelivery() {
    this.showDeliveryFrame = true;
  }

  noDelivery() {
    this.cartStepper.next();
  }
}
