import {Component, OnDestroy, OnInit} from '@angular/core';
import {SaleChannelService} from './lib/services/sale-channel.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ErrorService} from './lib/services/error.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SharedVariablesService} from './lib/services/shared-variables.service';
import {Location} from '@angular/common';

declare const klaro: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'keyneosoft-giphar';
  public klaroConsentManager: any;
  private errorSubscription: Subscription;
  private urlChangeSubscription: Subscription;
  private routeEventSubscription: Subscription;
  public eResaMode = false;


  constructor(private location: Location,
              private titleService: Title,
              private router: Router,
              private errorService: ErrorService,
              private snackBar: MatSnackBar,
              public sharedVariablesService: SharedVariablesService,
              private saleChannelService: SaleChannelService) {
  }

  ngOnInit(): void {
    const self = this;
    this.errorSubscription = this.errorService.error.subscribe((message) => {
      self.snackBar.open(message, undefined, {
        panelClass: ['error-snackbar'],
      });
    });

    lastValueFrom(this.saleChannelService.getSaleChannel()).then((saleChannel) => {
      this.sharedVariablesService.saleChannel = saleChannel;
      this.titleService.setTitle(saleChannel.title);

      if (this.sharedVariablesService.config.GOOGLE_ANALYTICS_TRACKING_ID) {
        const script = document.createElement('script');

        script.dataset.name = 'google-analytics';
        script.dataset.type = 'text/javascript';
        script.type = 'text/plain';
        script.text = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
          'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
          'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
          '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
          '})(window,document,\'script\',\'dataLayer\',\'' + this.sharedVariablesService.config.GOOGLE_ANALYTICS_TRACKING_ID.trim() + '\');';

        document.head.append(script);

        // Klaro for GPDR management
        klaro.setup(window['klaroConfig']);
        this.klaroConsentManager = klaro.getManager(window['klaroConfig']);
        this.klaroConsentManager.watch(this.sharedVariablesService);
        // Set googleAnalyticsConsent based on klaro states value
        if (this.klaroConsentManager && this.klaroConsentManager.states && this.klaroConsentManager.states['google-analytics']) {
          this.sharedVariablesService.googleAnalyticsConsent = this.klaroConsentManager.states['google-analytics'];
        } else {
          this.sharedVariablesService.googleAnalyticsConsent = false;
        }
      }
    });

    // Subscribe to changes for term GET parameter
    this.urlChangeSubscription = this.router.events.subscribe((events) => {
      if (this.location.path().indexOf('e-resa') >= 0) {
        this.eResaMode = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.urlChangeSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
    this.routeEventSubscription.unsubscribe();
  }
}
