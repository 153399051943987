import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class ProductVariant {
  id: string;
  productId: string;
  declinationId: string;
  ean: string;
  type: string;
  value: string;
  creationDate: Date;
  modificationDate: Date;
  stock: number;

  constructor(id: string, productId: string, declinationId: string, ean: string, type: string, value: string, creationDate: Date, modificationDate: Date, stock: number) {
    this.id = id;
    this.productId = productId;
    this.declinationId = declinationId;
    this.ean = ean;
    this.type = type;
    this.value = value;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.stock = stock;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductVariantAdapter implements Adapter<ProductVariant> {
  adapt(item: any): ProductVariant {
    if (item) {
      return new ProductVariant(
        item.id,
        item.productId,
        item.declinationId,
        item.ean,
        item.type,
        item.value,
        item.creationDate ? new Date(item.creationDate) : undefined,
        item.modificationDate ? new Date(item.modificationDate) : undefined,
        item.stock
      );
    } else {
      return undefined;
    }
  }
}
