import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { OfferConfiguration, OfferConfigurationAdapter } from './OfferConfiguration';
import { PackSelection, PackSelectionAdapter } from './PackSelection';
import { ProductSelection, ProductSelectionAdapter } from './ProductSelection';

export class Promotion {
  id?: string;
  name: string;
  description: string;
  organisationId: string;
  offerId: string;
  offerConfiguration: OfferConfiguration = new OfferConfiguration();
  productSelection: ProductSelection = new ProductSelection();
  packSelections: PackSelection = new PackSelection();
  active: boolean = true;
  creationDate?: Date;
  modificationDate?: Date;
  validityStartDate: Date;
  validityEndDate: Date;

  constructor(id?: string, name?: string, description?: string, organisationId?: string, offerId?: string, offerConfiguration?: OfferConfiguration,
              productSelection?: ProductSelection, packSelections?: PackSelection, active: boolean = false, creationDate?: Date, modificationDate?: Date,
              validityStartDate?: Date, validityEndDate?: Date) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.organisationId = organisationId;
    this.offerId = offerId;
    this.offerConfiguration = offerConfiguration;
    this.productSelection = productSelection;
    this.packSelections = packSelections;
    this.active = active;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.validityStartDate = validityStartDate;
    this.validityEndDate = validityEndDate;
  }

  /**
   * Returns whether the Promotion was, is or will be active depending of its opening + closing dates.
   * @return int -1 if active in past or closed, 0 if active now and +1 if active in the future.
   */
  public activeStatus() {
    const now = new Date();
    // Opened now
    if (this.active && (!(this.validityStartDate && this.validityEndDate) ||
      (this.validityStartDate && this.validityEndDate &&
        this.validityStartDate <= now
        && this.validityEndDate > now))) {
      return 0;
    } else if (this.active && this.validityStartDate && this.validityStartDate > now) {
      return 1; // Active in future
    } else {
      return -1; // Active in past or closed
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class PromotionAdapter implements Adapter<Promotion> {

  adapt(item: any): Promotion {
    const offerConfigurationAdapter: OfferConfigurationAdapter = new OfferConfigurationAdapter();
    const productSelectionAdapter: ProductSelectionAdapter = new ProductSelectionAdapter();
    const packSelectionAdapter: PackSelectionAdapter = new PackSelectionAdapter();

    return new Promotion(
      item.id,
      item.name,
      item.description,
      item.organisationId,
      item.offerId,
      item.offerConfiguration ? offerConfigurationAdapter.adapt(item.offerConfiguration) : undefined,
      item.productSelection ? productSelectionAdapter.adapt(item.productSelection) : undefined,
      item.packSelections ? packSelectionAdapter.adapt(item.packSelections) : undefined,
      item.active,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.validityStartDate ? new Date(item.validityStartDate.toString()) : undefined,
      item.validityEndDate ? new Date(item.validityEndDate.toString()) : undefined
    );
  }
}
