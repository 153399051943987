import { Component, Input } from '@angular/core';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { SpinnerType } from 'ngx-ui-loader/lib/utils/types';
import { Product } from '../../lib/model/Product';
import { ProductService } from '../../lib/services/product.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-product-item-small',
  templateUrl: './product-item-small.component.html',
  styleUrls: ['./product-item-small.component.scss']
})
export class ProductItemSmallComponent {
  @Input() product: Product;
  @Input() set productId(value: string) {
    this.pId = value;
    this.loadProductFromId(this.pId);
  }
  public pId: string;
  public loaderType: SpinnerType = SPINNER.threeStrings;

  constructor(private ngxLoaderService: NgxUiLoaderService,
              protected productService: ProductService) { }

  private loadProductFromId(productId: string) {
    if (productId) {
      const loaderId = productId;
      this.product = undefined;
      const self = this;

      this.ngxLoaderService.startLoader('loader-product-' + loaderId);
      lastValueFrom(this.productService.getProduct(productId)).then((product) => {
        self.product = product;
        this.ngxLoaderService.stopLoader('loader-product-' + loaderId);
      });
    }
  }
}
