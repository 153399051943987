import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class ProductTag {
  id: string;
  name: string = '';
  url: string = '';

  constructor(id: string, name?: string, url?: string) {
    this.id = id;
    this.name = name;
    this.url = url;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTagAdapter implements Adapter<ProductTag> {
  adapt(item: any): ProductTag {
    return new ProductTag(
      item.id,
      item.name,
      item.url
    );
  }
}
