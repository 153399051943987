import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {OAuth2Client} from '../../lib/model/OAuth2Client';
import {AuthenticationService} from '../../lib/services/authentication.service';
import packageInfo from '../../../../package.json';
import {Customer} from '../../lib/model/Customer';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public customer: Customer;
  public version: string = packageInfo.version;
  public oAuth2Clients: OAuth2Client[] = [];
  public oAuthBaseUrl: string;
  public showForm = false;

  constructor(public dialog: MatDialog,
              public authenticationService: AuthenticationService,
              public sharedVariablesService: SharedVariablesService,
              private router: Router,
              private route: ActivatedRoute) {
    if (this.sharedVariablesService.env === 'PROD') {
      this.oAuthBaseUrl = 'https://giphar.keyneosoft.io';
    } else {
      this.oAuthBaseUrl = 'https://giphar.staging.keyneosoft.io';
    }
  }

  ngOnInit(): void {
    this.authenticationService.logout(false);

    if (this.route.snapshot.queryParamMap.get('token')) {
      this.sharedVariablesService.clearLoginInfo();
      const token = this.route.snapshot.queryParamMap.get('token');
      lastValueFrom(this.authenticationService.loginWithToken(token)).then((user) => {
        this.sharedVariablesService.clear();
        this.redirectAfterLogin();
      });
    }

    this.loginForm = new FormGroup({
      login: new FormControl(undefined, Validators.required),
      password: new FormControl(undefined, Validators.required),
    });

    lastValueFrom(this.authenticationService.getOAuth2Clients()).then((clients) => {
      this.oAuth2Clients = clients;
      if (!this.oAuth2Clients) {
        this.showForm = true;
      }
    });
  }

  onSubmit(): void {
    this.sharedVariablesService.clearLoginInfo();
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const self = this;

    lastValueFrom(this.authenticationService.customerLogin(this.loginForm.get('login').value, this.loginForm.get('password').value))
      .then((customer) => {
        const subject = new Subject<Customer>();
        subject.next(customer);

        self.authenticationService.currentUserCustomerSubject.next(customer);
        self.loading = false;
        this.customer = customer;
        this.authenticationService.saveUser();

        this.router.navigate(['/catalogue']);
      }, (error) => {
        self.loading = false;
      });
  }

  private redirectAfterLogin(): void {
    // If want to access a specific page after login, redirect
    if (this.route.snapshot.queryParamMap.get('returnUrl')) {
      this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl').valueOf()]);
    } else { // Otherwise go to catalog page
      this.router.navigate(['/catalogue']);
    }
  }
}
