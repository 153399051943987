import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class LocationStatusEvent {
  id: string;
  locationId: string;
  status: string;
  date: Date;
  userId: string;

  constructor(id?: string, locationId?: string, status?: string, date?: Date, userId?: string) {
    this.id = id;
    this.locationId = locationId;
    this.status = status;
    this.date = date;
    this.userId = userId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocationStatusEventAdapter implements Adapter<LocationStatusEvent> {
  adapt(item: any): LocationStatusEvent {
    return new LocationStatusEvent(
      item.id,
      item.locationId,
      item.status,
      item.date ? new Date(item.date.toString()) : undefined,
      item.userId
    );
  }
}
