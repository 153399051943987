import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Promotion, PromotionAdapter } from '../model/Promotion';
import { PromotionPage, PromotionPageAdapter } from '../model/PromotionPage';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends ApiService {

  constructor(private http: HttpClient,
              private promotionPageAdapter: PromotionPageAdapter,
              private promotionAdapter: PromotionAdapter) {
    super();
  }

  getPromotion(promotionId: string): Observable<Promotion> {
    const params = new HttpParams();

    return this.http.get<Promotion>(this.baseUri + 'promotions/' + promotionId, {params})
      .pipe(map((item) => this.promotionAdapter.adapt(item)));
  }

  getPromotions(page: number = 0, pageSize: number = 20, discountId?: string): Observable<PromotionPage> {
    let params = new HttpParams()
      .set('size', pageSize.toString())
      .set('page', page.toString());

    if (discountId) {
      params = params.set('discountId', discountId);
    }

    params = params.set('active', 'true');
    params = params.set('validityDate', (new Date()).toISOString());

    return this.http.get<PromotionPage>(this.baseUri + 'promotions', {params})
      .pipe(map((item) => this.promotionPageAdapter.adapt(item)));
  }
}
