<footer class="giphar-footer">
  <div class="section no-padding">
    <div class="container row">
      <div class="group col-md-4 col-xs-12">
        <h3><mat-icon svgIcon="force-touch-double-tap"></mat-icon> Rejoignez la communauté</h3>
        <div class="text-center social">
          <a href="https://www.facebook.com/pharmaciengiphar/" target="_blank">
            <mat-icon svgIcon="social-media-facebook"></mat-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCk0rvV494gz21JiZoOVekkA" target="_blank">
            <mat-icon svgIcon="social-video-youtube"></mat-icon>
          </a>
          <a href="https://fr.linkedin.com/company/giphar" target="_blank">
            <mat-icon svgIcon="professional-network-linkedin"></mat-icon>
          </a>
          <a href="https://twitter.com/pharmagiphar" target="_blank">
            <mat-icon svgIcon="social-media-twitter"></mat-icon>
          </a>
        </div>
      </div>
      <div class="group col-md-4 col-xs-12">
        <h3><mat-icon svgIcon="medical-app-smartphone-touch"></mat-icon> Retrouvez notre appli</h3>
        <div class="text-center mb10">
          <a href="https://www.pharmaciengiphar.com/applications-giphar" target="_blank" class="grey">
            <img class="app-icon" src="/assets/images/giphar-app.png"/><br>
            <span class="grey small">Pharmacien</span>
          </a>
        </div>
      </div>
      <div class="group col-md-4 col-xs-12">
        <h3><mat-icon svgIcon="touch-finger-1"></mat-icon> Visitez également nos autres sites</h3>
        <div class="row links">
          <div class="col-md-6 col-xs-6"><a href="https://www.libeoz.fr/" target="_blank">Vente de matériel médical</a></div>
          <div class="col-md-6 col-xs-6"><a href="https://rejoindre.pharmaciengiphar.com/?utm_source=GT-ecom&utm_medium=link&utm_campaign=GT-ecom" target="_blank">Recrutement d'adhérents</a></div>
        </div>
        <div class="row links">
          <div class="col-md-6 col-xs-6"><a href="https://www.laboratoire-giphar.fr/" target="_blank">Les soins de pharmacien</a></div>
          <div class="col-md-6 col-xs-6"><a href="http://portail.pharmaciengiphar.fr/home/?utm_source=GT-ecom&utm_medium=link&utm_campaign=GT-ecom" target="_blank">Genius</a></div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-links">
    <div class="section no-padding">
      <div class="row container ">
        <div class="col-md-3 col-xs-12">&copy; 2021 Giphar Groupe</div>
        <div class="col-md-9 col-xs-12 right-links">
          <a href="http://portail.pharmaciengiphar.fr/home/?utm_source=GT-ecom&utm_medium=link&utm_campaign=GT-ecom" target="_blank">Genius</a>
          <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#101;&#114;&#118;&#105;&#99;&#101;&#46;&#99;&#111;&#109;&#109;&#101;&#114;&#99;&#105;&#97;&#108;&#64;&#103;&#105;&#112;&#104;&#97;&#114;&#46;&#110;&#101;&#116;" target="_blank">&#67;&#111;&#110;&#116;&#97;&#99;&#116;</a>
          <a href="https://img.keyneosoft.io/giphar/public/pdf/mentions_legales.pdf" target="_blank">Mentions légales</a>
          <a href="https://rejoindre.pharmaciengiphar.com/?utm_source=GT-ecom&utm_medium=link&utm_campaign=GT-ecom" target="_blank">Rejoindre le groupement Giphar</a>
        </div>
      </div>
    </div>
  </div>
</footer>
