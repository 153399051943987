import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../model/Config';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private userChangedLanguage: boolean = false;
  private fallbackLanguage: Map<string, string> = new Map();
  private availableLanguages: string[] = ['fr-FR'];
  private defaultLanguage: string = undefined;
  public currentLanguage: string = undefined;
  public languageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService, @Inject(DOCUMENT) private document: Document) {
    this.fallbackLanguage.set('en-US', 'en-GB');

    let config: Config = new Config();
    this.addAvailableLanguages(config.AVAILABLE_LANGUAGES);
    this.setDefaultLanguage(config.DEFAULT_LANGUAGE);
    this.currentLanguage = config.DEFAULT_LANGUAGE;
    this.autoSelectLanguage();
  }

  getCurrentLanguage(): string {
    return this.returnLanguageOrFallback(this.currentLanguage);
  }

  setLanguage(locale: string, isUserChoice: boolean = false) {
    if (isUserChoice) {
      this.userChangedLanguage = true;
      sessionStorage.setItem('locale', locale);
    }
    this.currentLanguage = locale;
    this.translate.use(this.returnLanguageOrFallback(locale));
    this.languageChanged.emit(this.returnLanguageOrFallback(locale));
    if (locale) {
      this.document.documentElement.lang = this.returnLanguageOrFallback(locale).substr(0, 2);
    }
  }

  setDefaultLanguage(language: string) {
    this.defaultLanguage = language;
    this.translate.setDefaultLang(this.returnLanguageOrFallback(language));
    sessionStorage.setItem('default-locale', language);
  }

  addAvailableLanguages(languages: string[]) {
    this.availableLanguages = languages;
    this.translate.addLangs(languages.map(locale => this.returnLanguageOrFallback(locale)));
    sessionStorage.setItem('available-locales', JSON.stringify(languages));

    this.autoSelectLanguage();
  }

  clearLocaleSessionStorage() {
    sessionStorage.removeItem('available-locales');
    sessionStorage.removeItem('default-locale');
    this.translate.langs = this.availableLanguages;
    this.translate.setDefaultLang(this.defaultLanguage);
  }

  getAvailableLanguages() {
    return this.availableLanguages;
  }

  public returnLanguageOrFallback(language: string) {
    if (this.fallbackLanguage.has(language)) {
      return this.fallbackLanguage.get(language);
    }
    return language;
  }

  private autoSelectLanguage() {
    if (!this.userChangedLanguage && !sessionStorage.getItem('locale')) {
      if (this.getAvailableLanguages().includes(this.translate.getBrowserCultureLang())) {
        this.setLanguage(this.translate.getBrowserCultureLang());
      } else {
        this.setLanguage(this.defaultLanguage);
      }
    } else if (sessionStorage.getItem('locale')) {
      if (this.getAvailableLanguages().includes(sessionStorage.getItem('locale'))) {
        this.setLanguage(sessionStorage.getItem('locale'));
      } else {
        this.setLanguage(this.defaultLanguage);
      }
    }
  }
}
