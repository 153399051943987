import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {IImage} from 'ng-simple-slideshow';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {lastValueFrom, Subscription} from 'rxjs';
import {Advertisement} from '../../lib/model/Advertisement';
import {Category} from '../../lib/model/Category';
import {Product} from '../../lib/model/Product';
import {Promotion} from '../../lib/model/Promotion';
import {AdvertisementService} from '../../lib/services/advertisement.service';
import {CategoryService} from '../../lib/services/category.service';
import {GoogleAnalyticsService} from '../../lib/services/google-analytics.service';
import {OfferService} from '../../lib/services/offer.service';
import {ProductService} from '../../lib/services/product.service';
import {PromotionService} from '../../lib/services/promotion.service';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelect') categorySelect;
  public category: Category;
  public categories: Category[];
  public categoriesToDisplay: Category[];
  public products: Product[] = [];
  private queryParamsSubscription: Subscription;
  private paramsSubscription: Subscription;
  private rootCategoryCode: string;
  public allProductsLoaded = false;
  public disableScroll = false;
  public productsLoading = false;
  public total = 0;
  public showGoUpArrow = false;
  public searchTerm: string;
  public packs: Promotion[] = [];
  public carouselAdvertisements: Advertisement[] = [];
  public carouselAdvertisementsImages: IImage[] = [];
  public allProductsCategoryCode: string;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private location: Location,
              private titleService: Title,
              private ngxLoaderService: NgxUiLoaderService,
              private productService: ProductService,
              private categoryService: CategoryService,
              private offerService: OfferService,
              private transactionService: TransactionService,
              private advertisementService: AdvertisementService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private promotionService: PromotionService) {
  }

  ngOnInit(): void {
    this.category = undefined;
    this.products = [];

    // Search term if passed in GET parameters
    this.searchTerm = this.route.snapshot.queryParamMap.get('term');
    // Subscribe to changes for term GET parameter
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      if (params.keys.includes('term')) {
        this.searchTerm = params.get('term');
        this.loadProducts(true);
      }
    });

    // Subscribe to params changes
    const self = this;
    this.paramsSubscription = this.route.paramMap.subscribe((params) => {
      // Category code in params
      self.rootCategoryCode = params.has('categorycode') ? params.get('categorycode') : undefined;
      // Brand code in params
      self.initProductsAndFilters();
    });

    // We look for home banner
    lastValueFrom(this.advertisementService.getAdvertisements('B2BWEB', 'PRODUCTS_CAROUSEL')).then((advertisements) => {
      if (advertisements && advertisements.length > 0) {
        this.carouselAdvertisements = advertisements;
        this.carouselAdvertisements.forEach((ad) => {
          this.carouselAdvertisementsImages.push({
            href: ad.redirectionUrl,
            title: undefined,
            url: ad.mediaUrl});
        });
      }
    });

    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll.bind(this), true);
    this.queryParamsSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  /**
   * As we use RouteReuseStrategy (via helpers/routing.ts class) for this component (to retrieve it from storage when navigating back from
   * a product details page), we must disable scrolling events when moving away from the page.
   */
  ngOnDetach(): void {
    this.disableScroll = true;
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * As we use RouteReuseStrategy (via helpers/routing.ts class) for this component (to retrieve it from storage when navigating back from
   * a product details page), we must re-enable scrolling events when moving back to this page.
   */
  ngOnAttach(): void {
    this.disableScroll = false;
    // Force reload all products
    this.loadProducts(true, true);
    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * Initialize filters and load products.
   */
  private initProductsAndFilters(): void {
    const self = this;
    this.products = [];

    if (this.categories && this.categories.length > 0) {
      this.retrieveCurrentCategory(this.categories);

      self.checkForPacksDisplay();
      self.loadProducts(true);
    } else {
      // Fetch categories and retrieve current (linked with sale channel)
      lastValueFrom(this.categoryService.getCategoriesBySaleChannel(this.sharedVariablesService.saleChannel, 2)).then((categories) => {
        if (categories.length === 1) {
          this.allProductsCategoryCode = categories[0].code;
        }
        this.categories = categories;
        this.retrieveCurrentCategory(categories);

        self.checkForPacksDisplay();
        self.loadProducts(true);
      });
    }
  }

  private retrieveCurrentCategory(categories: Category[]): void {
    const self = this;
    if (categories && categories.length > 0) {
      if (categories.length === 1) {
        self.categoriesToDisplay = categories[0].children;
      } else {
        self.categoriesToDisplay = categories;
      }

      if (!this.rootCategoryCode || this.rootCategoryCode === this.allProductsCategoryCode) {
        self.category = categories[0];
      } else {
        self.categoriesToDisplay.forEach((cat) => {
          if (cat.code === this.rootCategoryCode) {
            this.category = cat;
          }
        });
      }
    }
  }

  /**
   * Compute whether packs should be fetched and displayed.
   */
  private checkForPacksDisplay(): void {
    if (this.sharedVariablesService.config.DISPLAY_PACKS && (!this.rootCategoryCode || !this.category.parentCategoryCode) && !this.searchTerm) {
      this.getPacks();
    } else {
      this.packs = undefined;
    }
  }

  /**
   * Get packs to display on home page
   */
  private getPacks(): void {
    lastValueFrom(this.offerService.getOffers(0, 20, 'PACK')).then((offerPage) => {
      const offers = offerPage.content;

      if (offers.length > 0) {
        const offer = offers[0];

        lastValueFrom(this.promotionService.getPromotions(0, 10, offer.id)).then((promotionPage) => {
          if (promotionPage.content.length > 0) {
            this.packs = promotionPage.content;
          }
        });
      }
    });
  }

  /**
   * When product-item list is scrolled, load more products.
   */
  loadProducts(forceReload: boolean = false, fullLoad: boolean = false): void {
    if ((!this.productsLoading && !this.allProductsLoaded && !this.disableScroll) || forceReload) {
      // Fetch products
      const start: number = forceReload ? 0 : this.products.length;
      const size: number = fullLoad ? this.products.length : 16;
      this.ngxLoaderService.start();
      this.productsLoading = true;
      const self = this;
      lastValueFrom(this.productService.getProducts(this.category ? this.category.code : undefined,
        this.searchTerm,
        start,
        size,
        undefined
        )).then((productPage) => {
        self.productsLoading = false;
        self.allProductsLoaded = productPage.last;

        // Send to GA
        this.googleAnalyticsService.viewItemList(productPage.products, this.searchTerm ? 'search_results' : (this.category ? this.category.code : undefined));

        if (!forceReload) {
          self.products = this.products.concat(productPage.products);
        } else {
          self.products = productPage.products;
          if (!fullLoad) {
            self.goUp();
          }
        }
        self.ngxLoaderService.stop();
      });
    }
  }

  /**
   * Scroll event.
   */
  scroll($event): void {
    this.showGoUpArrow = window.scrollY > 0;
  }

  /**
   * Go to the top of the page.
   */
  goUp(): void {
    window.scroll(0, 0);
  }

  endSearch(): void {
    this.searchTerm = undefined;
    this.location.replaceState('/catalogue');
    this.loadProducts(true);
  }

  navigateTo(value?: string): void {
    window.setTimeout(() => {
      if (value) {
        this.router.navigate(['/catalogue/', value]);
      } else {
        this.router.navigate(['/catalogue/']);
      }
    }, 300);
  }

  clickCategory(name: string): void {
    // Set page title
    if (name) {
      this.titleService.setTitle(this.sharedVariablesService.saleChannel.title + ' - ' + name);
    } else {
      this.titleService.setTitle(this.sharedVariablesService.saleChannel.title);
    }

    // Send GA event
    this.googleAnalyticsService.eventEmitter('category_changed', {category: name});
  }
}
