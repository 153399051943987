import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import {StorageLocation, StorageLocationAdapter} from './StorageLocation';
import { Article, ArticleAdapter } from './Article';
import { StockType } from './StockType';
import { OrderLineStatusEvent, OrderLineStatusEventAdapter } from './OrderLineStatusEvent';

export class OrderLine {
  productId: string;
  ean: string;
  articleId: string;
  name: string;
  description: string;
  quantity: number;
  quantityWithCoefficient: number; // quantityCoefficient in kotlin
  salesUnitLabel: string;
  unitPrice: number;
  unitPurchasingPrice: number;
  totalAmount: number;
  totalPurchasingAmount: number;

  defaultImage: string;
  stockType: StockType;
  categoryName: string;
  externalBusinessId: string;
  options: OrderLineOption[];

  orderLineStatus: OrderLineStatus;
  events: OrderLineStatusEvent[];
  orderLineId: string;
  advanceRate: number;
  totalAdvanceAmount: number;

  totalLeftToPayAmount: number;
  comment: string;
  minQuantity: number;
  maxQuantity: number;
  targetLocationId: string;
  deliveryLocationId: string;
  locationId: string;

  preparationLocation: StorageLocation;

  article: Article;
  alertMessages: string[];

  constructor(productId?: string, ean?: string, articleId?: string, name?: string, description?: string, quantity?: number,
              quantityWithCoefficient?: number, salesUnitLabel?: string, unitPrice?: number, unitPurchasingPrice?: number,
              totalAmount?: number, totalPurchasingAmount?: number, defaultImage?: string, stockType?: StockType, categoryName?: string,
              externalBusinessId?: string, options?: OrderLineOption[], orderLineStatus?: OrderLineStatus, events?: OrderLineStatusEvent[],
              orderLineId?: string, advanceRate?: number, totalAdvanceAmount?: number, totalLeftToPayAmount?: number,
              comment?: string, minQuantity?: number, maxQuantity?: number, targetLocationId?: string, deliveryLocationId?: string,
              locationId?: string, preparationLocation?: StorageLocation, article?: Article, alertMessages?: string[]) {
    this.productId = productId;
    this.ean = ean;
    this.articleId = articleId;
    this.name = name;
    this.description = description;
    this.quantity = quantity;
    this.quantityWithCoefficient = quantityWithCoefficient;
    this.salesUnitLabel = salesUnitLabel;
    this.unitPrice = unitPrice;
    this.unitPurchasingPrice = unitPurchasingPrice;
    this.totalAmount = totalAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.defaultImage = defaultImage;
    this.stockType = stockType;
    this.categoryName = categoryName;
    this.externalBusinessId = externalBusinessId;
    this.options = options;
    this.orderLineStatus = orderLineStatus;
    this.events = events;
    this.orderLineId = orderLineId;
    this.advanceRate = advanceRate;
    this.totalAdvanceAmount = totalAdvanceAmount;
    this.totalLeftToPayAmount = totalLeftToPayAmount;
    this.comment = comment;
    this.minQuantity = minQuantity;
    this.maxQuantity = maxQuantity;
    this.targetLocationId = targetLocationId;
    this.deliveryLocationId = deliveryLocationId;
    this.preparationLocation = preparationLocation;
    this.locationId = locationId;
    this.article = article;
    this.alertMessages = alertMessages;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderLineAdapter implements Adapter<OrderLine> {
  adapt(item: any): OrderLine {
    const orderLineStatusEventAdapter: OrderLineStatusEventAdapter = new OrderLineStatusEventAdapter();
    const orderLineOptionAdapter: OrderLineOptionAdapter = new OrderLineOptionAdapter();
    const storageLocationAdapter: StorageLocationAdapter = new StorageLocationAdapter();
    const articleAdapter: ArticleAdapter = new ArticleAdapter();

    return new OrderLine(
      item.productId,
      item.ean,
      item.articleId,
      item.name,
      item.description,
      item.quantity,
      item.quantityWithCoefficient,
      item.salesUnitLabel,
      item.unitPrice,
      item.unitPurchasingPrice,
      item.totalAmount,
      item.totalPurchasingAmount,
      item.defaultImage,
      item.stockType,
      item.categoryName,
      item.externalBusinessId,
      item.options ? item.options.map((e) => {
        e = orderLineOptionAdapter.adapt(e);
        return e;
      }) : [],
      item.orderLineStatus ? OrderLineStatus[item.orderLineStatus] : undefined,
      item.events ? item.events.map((e) => {
        e = orderLineStatusEventAdapter.adapt(e);
        return e;
      }) : undefined,
      item.orderLineId,
      item.advanceRate,
      item.totalAdvanceAmount,
      item.totalLeftToPayAmount,
      item.comment,
      item.minQuantity,
      item.maxQuantity,
      item.targetLocationId,
      item.deliveryLocationId,
      item.locationId,
      item.preparationLocation ? storageLocationAdapter.adapt(item.preparationLocation) : undefined,
      item.article ? articleAdapter.adapt(item.article) : undefined,
      item.alertMessages ? item.alertMessages : []
    );
  }
}

export enum OrderLineStatus {
  WITHDRAWN = 'WITHDRAWN',
  WAITING_FOR_IMMEDIATE_WITHDRAWAL = 'WAITING_FOR_IMMEDIATE_WITHDRAWAL',
  IN_RESERVATION = 'IN_RESERVATION',
  IN_SUPPLY = 'IN_SUPPLY',
  CANCELED = 'CANCELED',
}

export class OrderLineOption {
  productOptionId: string;
  productOptionExternalBusinessId: string;
  productOptionName: string;
  productOptionPrice: number;
  productOptionPurchasingPrice: number;
  productOptionValueId: string;
  productOptionValueName: string;
  productOptionValueValue: string;
  productOptionValuePrice: number;
  productOptionValuePurchasingPrice: number;
  productOptionValueIds: string[];
  productOptionValueNames: string[];
  productOptionValueValues: string[];
  productOptionValueExternalBusinessId: string;
  values: string[];
  productOptionExternalLineId: string;
  productOptionValueExternalLineId: string;

  constructor(productOptionId?: string, productOptionExternalBusinessId?: string, productOptionName?: string,
              productOptionPrice?: number, productOptionPurchasingPrice?: number, productOptionValueId?: string,
              productOptionValueName?: string, productOptionValueValue?: string, productOptionValuePrice?: number,
              productOptionValuePurchasingPrice?: number, productOptionValueIds?: string[], productOptionValueNames?: string[],
              productOptionValueValues?: string[], productOptionValueExternalBusinessId?: string, values?: string[],
              productOptionExternalLineId?: string, productOptionValueExternalLineId?: string) {
    this.productOptionId = productOptionId;
    this.productOptionExternalBusinessId = productOptionExternalBusinessId;
    this.productOptionName = productOptionName;
    this.productOptionPrice = productOptionPrice;
    this.productOptionPurchasingPrice = productOptionPurchasingPrice;
    this.productOptionValueId = productOptionValueId;
    this.productOptionValueName = productOptionValueName;
    this.productOptionValueValue = productOptionValueValue;
    this.productOptionValuePrice = productOptionValuePrice;
    this.productOptionValuePurchasingPrice = productOptionValuePurchasingPrice;
    this.productOptionValueIds = productOptionValueIds;
    this.productOptionValueNames = productOptionValueNames;
    this.productOptionValueValues = productOptionValueValues;
    this.productOptionValueExternalBusinessId = productOptionValueExternalBusinessId;
    this.values = values;
    this.productOptionExternalLineId = productOptionExternalLineId;
    this.productOptionValueExternalLineId = productOptionValueExternalLineId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderLineOptionAdapter implements Adapter<OrderLineOption> {
  adapt(item: any): OrderLineOption {

    return new OrderLineOption (
      item.productOptionId,
      item.productOptionExternalBusinessId,
      item.productOptionName,
      item.productOptionPrice,
      item.productOptionPurchasingPrice,
      item.productOptionValueId,
      item.productOptionValueName,
      item.productOptionValueValue,
      item.productOptionValuePrice,
      item.productOptionValuePurchasingPrice,
      item.productOptionValueIds ? item.productOptionValueIds : [],
      item.productOptionValueNames ? item.productOptionValueNames : [],
      item.productOptionValueValues ? item.productOptionValueValues : [],
      item.productOptionValueExternalBusinessId,
      item.values ? item.values : [],
      item.productOptionExternalLineId
    );
  }
}
