import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

/**
 * Pipe to format date according to locale.
 */
@Pipe({
  name: 'dateformat',
})
/**
 * @deprecated use formatdate instead
 */
export class DateformatPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(date: Date|string, timezone: 'Europe/Paris', options = { weekday: 'long', month: 'long', day: 'numeric', timeZone: 'Europe/Paris' }) {
    date = this.dateWithTimeZone(timezone, date);
    options.timeZone = timezone;
    return 0;
    // return new Intl.DateTimeFormat(this.languageService.getCurrentLanguage(), options).format(date);
  }

  dateWithTimeZone(timezone: string, dateSrc: any) {
    const date = new Date(dateSrc);

    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime( date.getTime() + offset );

    return date;
  }
}
