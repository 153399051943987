import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from '../model/Item';
import { Option } from '../model/Option';
import { Product } from '../model/Product';
import { ProductVariant } from '../model/ProductVariant';
import { Transaction, TransactionAdapter } from '../model/Transaction';
import { ApiService } from './api.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService extends ApiService {

  constructor(private http: HttpClient,
              private sharedVariablesService: SharedVariablesService,
              private transactionAdapter: TransactionAdapter) {
    super();
  }

  /**
   * Get a single transaction from its id.
   * @param transactionId
   */
  public getTransaction(transactionId: string): Observable<Transaction> {
    const params = new HttpParams();

    return this.http.get<Transaction>(this.baseUri + 'transactions/' + transactionId, {params})
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Create / update transaction.
   * @param transaction
   */
  public createOrUpdateTransaction(transaction: Transaction): Observable<Transaction> {
    let transactionId = '';
    if (!transaction.id) {
      if (this.sharedVariablesService.saleChannel && this.sharedVariablesService.saleChannel.properties.idIngenico) {
        transactionId = this.sharedVariablesService.saleChannel.properties.idIngenico;
      } else {
        transactionId = 'CNC';
      }
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      transactionId += transaction.organisationId + Date.now().toString().substring(4) + randomNumber;
    } else {
      transactionId = transaction.id;
    }

    return this.http.put<Transaction>(this.baseUri + 'transactions/' + transactionId, this.transactionAdapter.prepare(transaction))
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Update transaction status.
   * @param transaction
   * @param statusCode
   * @param userId
   */
  public updateTransactionStatus(transaction: Transaction, statusCode: string, userId: string = 'web'): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.post<Transaction>(this.baseUri + 'transactions/' + transaction.id + '/status', {
      statusCode,
      userId
    }, {params}).pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Add a product-item to a transaction.
   * @param transaction
   * @param product
   * @param quantity
   * @param options
   */
  public addProduct(transaction: Transaction, product: ProductVariant|Product|Item, quantity: number, options: Option[] = []): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.post<Transaction>(this.baseUri + 'transactions/' + transaction.id + '/' + product.ean, {
      ean: product.ean,
      options,
      quantity,
    }, {params}).pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Delete a product-item from a transaction.
   * @param transaction
   * @param declinationId
   */
  public deleteProduct(transaction: Transaction, declinationId: string): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.delete(this.baseUri + 'transactions/' + transaction.id + '/' + declinationId, {params})
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }
}
