import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Item, ItemAdapter } from './Item';

export class Pack {
  public packInstanceId: string;
  public name: string;
  public totalAmount: number;
  public items: Item[] = [];


  constructor(packInstanceId: string, name: string, totalAmount: number, items: Item[]) {
    this.packInstanceId = packInstanceId;
    this.name = name;
    this.totalAmount = totalAmount;
    this.items = items;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PackAdapter implements Adapter<Pack> {
  adapt(item: any): Pack {
    const itemAdapter = new ItemAdapter();

    return new Pack(
      item.packInstanceId,
      item.name,
      item.totalAmount,
      item.items ? item.items.map(elt => itemAdapter.adapt(elt)) : []
    );
  }
}
