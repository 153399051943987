import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ElasticSearchResultAdapter} from '../model/ElasticSearchResult';
import {Product, ProductAdapter} from '../model/Product';
import {ProductPage, ProductPageAdapter} from '../model/ProductPage';
import { ProductScheme, ProductSchemeAdapter } from '../model/ProductScheme';
import {ApiService} from './api.service';
import {SharedVariablesService} from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends ApiService {

  constructor(private http: HttpClient,
              private adapter: ProductAdapter,
              private elasticAdapter: ElasticSearchResultAdapter,
              private productSchemeAdapter: ProductSchemeAdapter,
              private sharedVariablesService: SharedVariablesService,
              private productPageAdapter: ProductPageAdapter) {
    super();
  }

  /**
   * Return ProductScheme for current instance.
   */
  getProductScheme(): Observable<ProductScheme> {
    return this.http.get<ProductScheme>(this.baseUri + 'model/scheme/product/' + this.sharedVariablesService.instance)
      .pipe(map(item => this.productSchemeAdapter.adapt(item)));
  }

  /**
   * Get products for current organisation.
   * @param categoryCode Code of the category to search in.
   * @param searchTerm
   * @param from Index of product-item to return from.
   * @param size Size of result (number of products).
   * @param tags
   */
  public getProducts(categoryCode?: string, searchTerm?: string, from: number = 0, size: number = 12, tags?: string): Observable<ProductPage> {
    let params = new HttpParams();
    params = params.set('size', size.toString())
                   .set('page', Math.floor(from / size).toString())
                   .set('addStock', 'true');

    if (this.sharedVariablesService.transaction) {
      params = params.set('currentTransactionId', this.sharedVariablesService.transaction.id)
                     .set('saleChannelCodes', 'B2BWEB');
    }

    if (searchTerm) {
      params = params.set('searchValue', searchTerm);
    }

    if (categoryCode) {
      params = params.set('categories', categoryCode);
    }

    if (tags) {
      params = params.set('tags', tags);
    }

    return this.http.get<ProductPage>(this.baseUri + 'products', {params})
      .pipe(map(item => this.productPageAdapter.adapt(item)));
  }

  /**
   * Get a product by its ean / product id.
   * @param ean
   */
  public getProduct(ean: string): Observable<Product> {
    let params = new HttpParams();

    params = params.set('addStock', 'true');

    if (this.sharedVariablesService.transaction) {
      params = params.set('currentTransactionId', this.sharedVariablesService.transaction.id);
    }

    return this.http.get<Product>(this.baseUri + 'products/' + ean, {params})
      .pipe(map(item => this.adapter.adapt(item)));
  }
}
