import { Appointment } from './Appointment';

export class Delivery {
  expectedWithdrawalDate: string;
  expectedWithdrawalTime: string;
  appointmentId: string;
  appointment: Appointment;

  constructor(expectedWithdrawalDate?: string, expectedWithdrawalTime?: string, appointmentId?: string, appointment?: Appointment) {
    this.expectedWithdrawalDate = expectedWithdrawalDate;
    this.expectedWithdrawalTime = expectedWithdrawalTime;
    this.appointmentId = appointmentId;
    this.appointment = appointment;
  }
}
