<article class="product" [ngClass]="{ 'animate': animate }">
  <div class="row">
    <div class="col-xs-7 col-sm-7 col-md-12 col-xs-push-5 name-container">
      <div class="name" (click)="clickProduct()" [routerLink]="['/product', product.ean, product.slugify()]">{{ product.getNameOrCommercialName() }}</div>
      <div class="flex">
        <ng-container *ngFor="let propertyScheme of getProductScheme()?.productPropertySchemes">
          <div class="info" *ngIf="propertyScheme.visible && propertyScheme.visibleInProductList && product.properties[propertyScheme.code]">{{ product.properties[propertyScheme.code] }}</div>
        </ng-container>
      </div>
      <div class="description" *ngIf="product.shortDescription">{{ product.shortDescription }}</div>
      <div class="tags visible-xs">
        <ng-container *ngFor="let tagId of product.tags">
          <img class="tag-image" *ngIf="getTag(tagId) && getTag(tagId).url" [src]="getTag(tagId).url" [alt]="getTag(tagId).name"/>
          <span class="tag" *ngIf="getTag(tagId) && !getTag(tagId).url && getTag(tagId).name">{{ getTag(tagId).name }}</span>
          <span class="tag" *ngIf="!getTag(tagId)">{{ tagId }}</span>
        </ng-container>
      </div>
    </div>
    <div class="col-xs-5 col-sm-5 col-md-12 col-xs-pull-7">
      <img class="product-image link" (click)="clickProduct()" [routerLink]="['/product', product.ean, product.slugify()]" src="{{ product.getDefaultImage()?.url }}" [ngClass]="{outOfStock: (product.stock === 0)}" alt="{{ product.name }}">
      <div class="unavailable" *ngIf="(product.stock === 0)">
        <div class="col pt5"><span>Produit indisponible</span></div>
      </div>
      <div class="tags hidden-xs">
        <ng-container *ngFor="let tagId of product.tags">
          <img class="tag-image" *ngIf="getTag(tagId) && getTag(tagId).url" [src]="getTag(tagId).url" [alt]="getTag(tagId).name"/>
          <span class="tag" *ngIf="getTag(tagId) && !getTag(tagId).url && getTag(tagId).name">{{ getTag(tagId).name }}</span>
          <span class="tag" *ngIf="!getTag(tagId)">{{ tagId }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row mt5 actions">
    <div class="col-xs-6 col-sm-6 col-md-6 pt5"><span class="price" *ngIf="product.price" [innerHTML]="product.price | price"></span></div>
    <div class="col-xs-6 col-sm-6 col-md-6 text-right" [ngClass]="{ 'no-padding': isAlreadyInTransaction }">
      <ng-container *ngIf="!isAlreadyInTransaction">
        <button mat-flat-button color="primary" [disabled]="loading || (product.stock === 0)" attr.aria-label="Ajouter au panier"
                [class.spinner]="loading"
                (click)="product.hasOptions() ? askForOptions(product) : addToCart(product)"><mat-icon svgIcon="shopping-cart-add"></mat-icon></button>
      </ng-container>
      <ng-container *ngIf="isAlreadyInTransaction">
        <ng-container *ngIf="product.hasOptions(); else noOption">
          <div class="pt10 pr10 primary">Ajouté</div>
        </ng-container>
        <ng-template #noOption>
          <button mat-icon-button color="primary" (click)="decreaseQuantity(product)" attr.aria-label="Diminuer la quantité"><mat-icon>remove_circle</mat-icon></button>
          <strong>{{ sharedVariablesService.transaction?.itemsAsObject[product.id] ? sharedVariablesService.transaction?.itemsAsObject[product.id][product.ean] : 0 }}</strong>
          <button mat-icon-button color="primary" (click)="addToCart(product)" attr.aria-label="Augmenter la quantité"
                  [disabled]="sharedVariablesService.transaction?.itemsAsObject[product.id] && product.maxQuantityInTransaction && product.maxQuantityInTransaction <= sharedVariablesService.transaction?.itemsAsObject[product.id][product.ean]"><mat-icon>add_circle</mat-icon></button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</article>
