import {ProductOption, ProductOptionValue} from './ProductOption';

export class Option {
  productOptionId: string;
  productOptionValueId: string;
  productOption?: ProductOption;
  productOptionValue?: ProductOptionValue;
  productOptionValues?: ProductOptionValue[];
  productOptionValueIds?: string[];
  values?: string[];

  constructor(productOptionId: string,
              productOptionValueId: string,
              productOption?: ProductOption,
              productOptionValue?: ProductOptionValue,
              productOptionValues?: ProductOptionValue[],
              productOptionValueIds?: string[],
              values?: string[]) {
    this.productOptionId = productOptionId;
    this.productOptionValueId = productOptionValueId;
    this.productOptionValueIds = productOptionValueIds;
    if (productOption) {
      this.productOption = productOption;
    }
    if (productOptionValue) {
      this.productOptionValue = productOptionValue;
    }
    if (productOptionValues) {
      this.productOptionValues = productOptionValues;
    }
    if (values) {
      this.values = values;
    }
  }
}
