<div class="cart-panel" [ngClass]="{ 'inPage': sharedVariablesService.cartInPageVisible }" *ngIf="sharedVariablesService.transaction">
  <div class="details">
    <div class="articles">
      <h2 class="mt0 text-center">Mon panier</h2>
      <div class="pack" *ngFor="let pack of sharedVariablesService.transaction.packs">
        <div class="pack-headline">
          <div class="pack-name">{{ pack.name }}</div>
          <div class="pack-price">{{ pack.totalAmount | price }}</div>
        </div>
        <app-cart-item *ngFor="let item of pack.items;" [item]="item" [index]="item.rank"></app-cart-item>
      </div>
      <div *ngFor="let item of sharedVariablesService.transaction.items">
        <app-cart-item *ngIf="!item.hasPackApplied()" [item]="item" [index]="item.rank"></app-cart-item>
      </div>
      <div *ngIf="sharedVariablesService.transaction.items.length === 0" class="text-center">Votre panier est vide</div>
    </div>
    <div class="text-center" *ngIf="sharedVariablesService.transaction.items.length > 0">
      <button mat-button class="small" (click)="emptyCart()"><mat-icon class="font-size">delete_outline</mat-icon> Vider le panier</button>
    </div>
  </div>
  <div class="cart-summary">
    <div class="row subtotal" *ngIf="sharedVariablesService.transaction.totalDepositAmount > 0">
      <div class="col-xs-6 col-sm-6 col-md-6">Consigne</div>
      <div class="col-xs-6 col-sm-6 col-md-6 text-right">{{ sharedVariablesService.transaction.totalDepositAmount | price }}</div>
    </div>
    <div *ngIf="sharedVariablesService.transaction.additionalTaxes?.length > 0">
      <div class="row subtotal" *ngFor="let tax of sharedVariablesService.transaction.additionalTaxes">
        <div class="col-xs-6 col-sm-6 col-md-6">{{ tax.code }}</div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-right">{{ tax.totalAmount | price }}</div>
      </div>
    </div>
    <div *ngIf="sharedVariablesService.config.SHOW_SUBTOTAL_AND_DISCOUNTS">
      <div class="row subtotal">
        <div class="col-xs-6 col-sm-6 col-md-6">Sous-total</div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-right price">{{ (sharedVariablesService.transaction.totalAmount - sharedVariablesService.transaction.totalDeliveryCostAmount + sharedVariablesService.transaction.totalDiscountAmount) | price }}</div>
      </div>
      <div class="row discount" *ngIf="sharedVariablesService.transaction.totalDiscountAmount > 0">
        <div class="col-xs-6 col-sm-6 col-md-6">Total des remises</div>
        <div class="col-xs-6 col-sm-6 col-md-6 text-right price">-{{ sharedVariablesService.transaction.totalDiscountAmount | price }}</div>
      </div>
    </div>
    <div *ngIf="sharedVariablesService.transaction.totalDeliveryCostAmount > 0">
      <div class="row delivery mt20">
        <div class="col-md-8 col-sm-8 col-xs-8"><mat-icon color="primary" class="align-middle mr10" svgIcon="delivery-truck-2"></mat-icon> <strong>Frais de port Chronopost</strong></div>
        <div class="col-sm-4 col-md-4 col-xs-4 text-right price">{{ sharedVariablesService.transaction.totalDeliveryCostAmount | price }}</div>
      </div>
    </div>
    <div class="text-center accent mt10">
      Frais de port offert à partir de 200€ d'achat
    </div>
    <div class="row total">
      <div class="col-xs-6 col-sm-6 col-md-6">Total</div>
      <div class="col-xs-6 col-sm-6 col-md-6 text-right price">{{ sharedVariablesService.transaction.totalAmount | price }}</div>
    </div>
    <div class="mt20 text-center">
      <button mat-flat-button color="primary" [disabled]="sharedVariablesService.transaction?.items.length === 0" class="full-width" [routerLink]="['/panier']" (click)="beginCheckout()">Valider le panier</button>
    </div>
  </div>
</div>
