import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Address {
  id?: string;
  name: string;
  customerId?: string;
  addressType?: AddressType;
  contact?: string;
  phone?: string;
  line1: string;
  line2?: string;
  line3?: string;
  postalCode: string;
  city: string;
  country: string;
  modificationDate?: Date;
  creationDate?: Date;

  constructor(id?: string, name?: string, customerId?: string, addressType?: AddressType, contact?: string, phone?: string, line1?: string,
              line2?: string, line3?: string, postalCode?: string, city?: string, country?: string, modificationDate?: Date, creationDate?: Date) {
    this.id = id;
    this.name = name;
    this.customerId = customerId;
    this.addressType = addressType;
    this.contact = contact;
    this.phone = phone;
    this.line1 = line1;
    this.line2 = line2;
    this.line3 = line3;
    this.postalCode = postalCode;
    this.city = city;
    this.country = country;
    this.modificationDate = modificationDate;
    this.creationDate = creationDate;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AddressAdapter implements Adapter<Address> {

  adapt(item: any): Address {
    return new Address(
      item.id,
      item.name,
      item.customerId,
      item.addressType,
      item.contact,
      item.phone,
      item.line1,
      item.line2,
      item.line3,
      item.postalCode,
      item.city,
      item.country,
      new Date(item.modificationDate),
      new Date(item.creationDate),
    );
  }
}

export enum AddressType {
  INVOICE = 'INVOICE',
  DELIVERY = 'DELIVERY',
  OTHER = 'OTHER',
}
