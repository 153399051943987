import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Product, ProductAdapter } from './Product';

export class ProductPage {
  products: Product[];
  last: boolean = false;
  total: number;

  constructor(products: Product[] = [], last: boolean = false, total: number) {
    this.products = products;
    this.last = last;
    this.total = total;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductPageAdapter implements Adapter<ProductPage> {
  adapt(item: any): ProductPage {
    const productAdapter = new ProductAdapter();

    return new ProductPage(item.content.map(elt => productAdapter.adapt(elt)), item.last, item.total);
  }
}
