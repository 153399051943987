import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Page {
  totalPages: number;
  totalElements: number;
  size: number;
  currentPage: number;
  pageSizeOptions: number[];

  constructor(totalPages?: number, totalElements?: number, size?: number, currentPage?: number) {
    this.totalPages = totalPages;
    this.totalElements = totalElements;
    this.size = size;
    this.currentPage = currentPage;
    this.pageSizeOptions = [20, 50, 100, 200];
  }
}

@Injectable({
  providedIn: 'root',
})

export class PageAdapter implements Adapter<Page> {

  adapt(item: any): Page {
    return new Page(
      item.totalPages,
      item.totalElements,
      item.size,
      item.currentPage
    );
  }
}
