import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Offer {
  id: string;
  name: string;
  code: string;
  offerApplication: OfferApplication;
  triggerProductNumber: number = 1;
  discountFormat: DiscountFormat = DiscountFormat.IMMEDIATE_DISCOUNT;
  discountType: DiscountType;
  defaultValue: number;
  overloadableDefaultValue: boolean = true;
  properties: {[name: string]: any};

  constructor(id?: string, name?: string, code?: string, offerApplication?: OfferApplication, triggerProductNumber?: number,
              discountFormat?: DiscountFormat, discountType?: DiscountType, defaultValue?: number, overloadableDefaultValue: boolean = true,
              properties?: { [p: string]: any }) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.offerApplication = offerApplication;
    this.triggerProductNumber = triggerProductNumber;
    this.discountFormat = discountFormat;
    this.discountType = discountType;
    this.defaultValue = defaultValue;
    this.overloadableDefaultValue = overloadableDefaultValue;
    this.properties = properties;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferAdapter implements Adapter<Offer> {

  adapt(item: any): Offer {
    const offerApplicationAdapter: OfferApplicationAdapter = new OfferApplicationAdapter();

    return new Offer(
      item.id,
      item.name,
      item.code,
      item.offerApplication ? offerApplicationAdapter.adapt(item.offerApplication) : undefined,
      item.triggerProductNumber,
      item.discountFormat,
      item.discountType,
      item.defaultValue,
      item.overloadableDefaultValue,
      item.properties
    );
  }
}

export class OfferApplication {
  maxApplicationNumber: number;
  applicationType: ApplicationType;

  constructor(maxApplicationNumber: number, applicationType: ApplicationType) {
    this.maxApplicationNumber = maxApplicationNumber;
    this.applicationType = applicationType;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferApplicationAdapter implements Adapter<OfferApplication> {

  adapt(item: any): OfferApplication {
    return new OfferApplication(
      item.maxApplicationNumber,
      item.applicationType
    );
  }
}

export enum ApplicationType {
  BY_DAY = 'BY_DAY',
  BY_CART = 'BY_CART',
  BY_CUSTOMER = 'BY_CUSTOMER',
}

export enum DiscountFormat {
  IMMEDIATE_DISCOUNT = 'IMMEDIATE_DISCOUNT',
  CREDIT_VOUCHER = 'CREDIT_VOUCHER',
  POT = 'POT',
  NEW_PRICE = 'NEW_PRICE',
}

export enum DiscountType {
  RATE = 'RATE',
  AMOUNT = 'AMOUNT',
  CUSTOM = 'CUSTOM',
  PACK = 'PACK',
}
