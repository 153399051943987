<div class="header full-width">
  <div class="row">
    <div class="col-sm-4 col-xs-3 left-side" [ngClass]="{ 'hidden-xs': !sharedVariablesService.config.SHOW_MENU }">
      <button *ngIf="sharedVariablesService.config.SHOW_MENU" class="menu blacklisted" mat-flat-button color="primary" (click)="toggleMenu()"><i></i></button>
      <img class="logo link" [ngClass]="{ 'ml20': !sharedVariablesService.config.SHOW_MENU }" [routerLink]="[sharedVariablesService.transaction ? '/catalogue' : '']" src="/assets/images/logo.png" [alt]="sharedVariablesService?.saleChannel?.title"/>
    </div>
    <div class="visible-xs col-xs-6 text-center" [ngClass]="{ 'col-xs-6': !sharedVariablesService.config.SHOW_MENU, 'text-left': !sharedVariablesService.config.SHOW_MENU, 'pl20': !sharedVariablesService.config.SHOW_MENU }">
      <img class="logo link" [routerLink]="[sharedVariablesService.transaction ? '/catalogue' : '']" src="/assets/images/logo.png" [alt]="sharedVariablesService?.saleChannel?.title"/>
    </div>
    <div class="col-sm-4 col-xs-6 col-sm-push-4 text-right right-header">
      <button type="button" class="language-switcher link" [matMenuTriggerFor]="languageMenu" *ngIf="languageService.getAvailableLanguages().length > 1 && languageService.getCurrentLanguage()">
        <span class="locale">{{ languageService.getCurrentLanguage().substr(0, 2) | uppercase }}</span>
        <mat-icon color="primary">expand_more</mat-icon>
      </button>

      <button mat-button class="cartButton blacklisted" *ngIf="authenticationService.getCurrentUser()"  (click)="openMyAccount()" [ngClass]="{ animate: animateBadge }">
        <mat-icon class="material-icons blacklisted" svgIcon="single-man-circle"></mat-icon>
        <br><span class="blacklisted capitalize">Mon compte</span>
      </button>

      <button mat-button class="cartButton blacklisted" *ngIf="sharedVariablesService.customer" [disabled]="!sharedVariablesService.transaction || (sharedVariablesService.transaction?.items.length === 0 && !showCartPanel)" (click)="toggleCart()" [ngClass]="{ animate: animateBadge }">
        <mat-icon class="material-icons blacklisted" [matBadgeHidden]="sharedVariablesService.transaction?.items.length === 0"
                  matBadge="{{ sharedVariablesService.transaction?.totalItemQuantity > 0 ? sharedVariablesService.transaction?.totalItemQuantity : sharedVariablesService.transaction?.items.length }}" matBadgeColor="accent" matBadgePosition="above after" svgIcon="shopping-bag"></mat-icon>
        <br><span class="blacklisted amount" *ngIf="sharedVariablesService.transaction?.items.length > 0">{{ sharedVariablesService.transaction?.totalAmount | price }}</span>
        <span class="blacklisted capitalize" *ngIf="!sharedVariablesService.transaction || sharedVariablesService.transaction?.items.length === 0">Panier</span>
      </button>
    </div>
    <div class="col-sm-4 col-sm-pull-4 col-xs-12" *ngIf="sharedVariablesService.config.SHOW_SEARCHBAR && authenticationService.getCurrentUser()">
      <form class="flex mr10 ml10" [formGroup]="searchForm" (ngSubmit)="search()">
        <mat-form-field class="full-width search-input" [floatLabel]="'never'">
          <input matInput placeholder="Rechercher..." type="text" value="" formControlName="search">
        </mat-form-field>
        <button mat-flat-button type="submit" color="primary" class="small search-button" [disabled]="!searchForm.valid"><mat-icon class="font-size">search</mat-icon></button>
      </form>
    </div>
  </div>
</div>
<app-menu [hidden]="!showMenu" (toggle)="toggleMenu()" (clickoutside)="closeMenu()" [enableBlackListing]="true"></app-menu>
<app-cart-panel [hidden]="!showCartPanel" (toggle)="toggleCart()" (clickoutside)="closeCartPanel()" [enableBlackListing]="true"></app-cart-panel>
<mat-menu class="mat-menu-language" #languageMenu="matMenu">
  <button mat-menu-item (click)="changeLanguage(language)" *ngFor="let language of languageService.getAvailableLanguages()">
    {{ language.substr(0, 2) | uppercase }}
  </button>
</mat-menu>
