import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { SharedVariablesService } from '../services/shared-variables.service';

/**
 * Pipe to format price with superior €cents by inserting HTML.
 */
@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  constructor(private languageService: LanguageService,
              private sharedVariablesService: SharedVariablesService) {
  }

  transform(priceA: number) {
    if (isNaN(priceA)) {
      return '-';
    }
    const currency = this.sharedVariablesService.organisation?.currency ? this.sharedVariablesService.organisation?.currency : 'EUR';

    return new Intl.NumberFormat(this.languageService.getCurrentLanguage(), {style: 'currency', currency}).format(priceA);
  }
}
