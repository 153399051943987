<div class="product-infos">
  <div><img [src]="data.product.getDefaultImage()?.url" [alt]="data.product.getNameOrCommercialName()"> <strong>{{ data.product.getNameOrCommercialName() }}</strong></div>
  <div class="price">{{ data.product.price | price }}</div>
</div>
<h1 mat-dialog-title class="mb10 text-center">Options disponibles pour ce produit :</h1>
<form [formGroup]="form" (ngSubmit)="confirm()">
  <div mat-dialog-content>
    <div *ngFor="let option of data.product.options">
      <div class="option-wrapper" *ngIf="option.type === 'VALUE_CHOICE'">
        <div class="mt10 mb10">
          <strong>{{ option.name }}</strong>
        </div>
        <mat-form-field class="full-width">
          <mat-select [formControlName]="option.id">
            <mat-option *ngFor="let optionValue of option.values" [value]="optionValue.id" title="{{ optionValue.name }} {{ optionValue.price > 0 ? ('(+' + (optionValue.price | price) + ')') : '' }}">
              <img class="option-image" *ngIf="optionValue.image?.url" [src]="optionValue.image?.url" alt="optionValue.name">
              {{ optionValue.name }}
              <span *ngIf="optionValue.price > 0" class="extra"> (+{{ optionValue.price | price }})</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="option-wrapper" *ngIf="option.type === 'MULTIPLE_VALUE_CHOICE'">
        <div class="mt10 mb10">
          <strong>{{ option.name }}</strong><br>
          <div *ngIf="option.maxValueChoiceCount">({{ option.maxValueChoiceCount }} options à choisir<span *ngIf="option.allowExtraValueChoice">, +{{ option.pricePerExtraValueChoice | price }} par option supplémentaire</span>)</div>
        </div>
        <div class="full-width mb5 mt5" *ngFor="let optionValue of option.values">
          <mat-checkbox [(ngModel)]="multipleValuesOptions[option.id]['selected'][optionValue.id]" [ngModelOptions]="{standalone: true}"
                        [disabled]="multipleValuesOptions[option.id].maxValueChoiceCount && isMultipleOptionCompleted(option.id) && !multipleValuesOptions[option.id]['selected'][optionValue.id]">
            <img class="option-image" *ngIf="optionValue.image?.url" [src]="optionValue.image?.url" alt="optionValue.name">
            {{ optionValue.name }}
            <span class="extra" *ngIf="optionValue.price > 0">
              +{{ optionValue.price | price }}
            </span>
            <span class="extra" *ngIf="isMultipleOptionExtra(option.id, optionValue.id)">
              +{{ option.pricePerExtraValueChoice | price }}
            </span>
          </mat-checkbox>
        </div>
      </div>
      <div class="option-wrapper" *ngIf="option.type === 'TEXT'">
        <label class="mt10">
          <strong>{{ option.name }}</strong><br>
          <textarea class="text-option full-width" [formControlName]="option.id"></textarea>
        </label>
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <div class="flex subtotal">
      <div>Sous-total options</div>
      <div class="price">+{{ computeOptionsPrice() | price }}</div>
    </div>
    <div class="flex subtotal medium">
      <div>Total</div>
      <div class="price">{{ (computeOptionsPrice() + data.product.price) | price }}</div>
    </div>
    <div class="text-center">
      <button mat-flat-button color="primary" type="submit" [disabled]="!form.valid || !allOptionsCompleted()">Ajouter au panier</button>
    </div>
  </mat-dialog-actions>
</form>
