import { SaleChannelProperties } from './SaleChannel';
const config = require('../../../assets/config.json');

/**
 * Configuration du CNC. Surcharger le fichier de configuration config.json dans le dossier custom pour changer les settings d'une instance.
 */
export class Config {
  public INSTANCE_ID: string = undefined;
  public ROOT_ORGANISATION_ID: string = undefined;
  public SHOW_SUBTOTAL_AND_DISCOUNTS: boolean = true;
  public COPYRIGHT: string = undefined;
  public BRAND_WEBSITE: string = undefined;
  public MAILTO_CONTACT: string = undefined;
  public PACKS_LABEL: string = 'Nos offres';
  public SHOW_CART_IN_PAGE: boolean = false;
  public DISPLAY_PACKS: boolean = false;
  public SKIP_CONFIRM_CART: boolean = false;
  public SHOW_MENU: boolean = true;
  public SHOW_SEARCHBAR: boolean = true;
  public USER_ACCOUNTS: boolean = false;
  public PAYMENT_PARTNER: string = undefined;
  public DATA_COLLECTION_DISCLAIMER: string = "Les informations recueillies sur ce formulaire sont traitées par Keyneosoft aux fins de gestion de votre commande.";
  public ADVERTISEMENT_CONSENT: string = "Je souhaite recevoir des messages promotionnels et offres exclusives.";
  public GOOGLE_ANALYTICS_TRACKING_ID: string = '';
  public EMAIL_HINT: string = undefined;
  public EMAIL_CONFIRMATION: boolean = false;
  public CUSTOMER_SERVICE_URL: string = undefined;
  public ASK_FOR_COLLECTION_DATE_IN_CART: boolean = false;
  public AVAILABLE_LANGUAGES: string[] = ['fr-FR'];
  public DEFAULT_LANGUAGE: string = 'fr-FR';

  constructor() {
    Object.assign(this, config);
  }

  overrideWithSaleChannelProperties(saleChannelProperties: SaleChannelProperties) {
    Object.keys(this).forEach((key) => {
      if (saleChannelProperties[key]) {
        this[key] = (saleChannelProperties[key] === 'true' ? true : ((saleChannelProperties[key] === 'false') ? false : saleChannelProperties[key]));
      }
    });
  }
}
