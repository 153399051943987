import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaleChannel, SaleChannelAdapter } from '../model/SaleChannel';
import { WithdrawalDate, WithdrawalDateAdapter } from '../model/WithdrawalDate';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SaleChannelService extends ApiService {

  constructor(private http: HttpClient,
              private adapter: SaleChannelAdapter,
              private withdrawalDateAdapter: WithdrawalDateAdapter) {
    super();
  }

  public getEnv(): string {
    let url = new URL(window.location.href);

    // Comment here to switch between this differents site
    if (url.origin === 'http://localhost:4200') {
      url = new URL('https://giphar.b2b.staging.keyneo.io/');   // Giphar B2B
    }

    return url.origin;
  }

  getSaleChannels(isAdmin: boolean = false): Observable<SaleChannel[]> {
    let params = new HttpParams();

    params = params.set('active', 'true');
      // .set('class', 'Exhibition');


    // If not admin, user can see only opened exhibition
    // if (!isAdmin) {
    //   params = params.set('openedAt', (new Date()).toISOString());
    //   // We look for exhibitions closed up to 30 days ago
    //   params = params.set('closedAt', new Date(new Date().setDate(new Date().getDate() - 30)).toISOString());
    // }

    return this.http.get<SaleChannel[]>(this.baseUri + 'saleChannels', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getSaleChannel(saleChannelCode: string = 'B2BWEB'): Observable<SaleChannel> {
    return this.http.get<SaleChannel[]>(this.baseUri + 'saleChannels/' + saleChannelCode)
      .pipe(map(item => this.adapter.adapt(item)));
  }

  getSaleChannelFromBaseUrl(baseUrl: string): Observable<SaleChannel> {
    let params = new HttpParams();
    params = params.set('baseUrl', baseUrl);

    return this.http.get<SaleChannel>(this.baseUri + 'saleChannels', { params })
      .pipe(map(item => this.adapter.adapt(item[0])));
  }

  getWithdrawalDates(saleChannelCode: string): Observable<WithdrawalDate[]> {
    const params = new HttpParams();
    return this.http.get<WithdrawalDate[]>(this.baseUri + 'withdrawal/dates/' + saleChannelCode, { params })
      .pipe(map((data: any[]) => data.map(item => this.withdrawalDateAdapter.adapt(item))));
  }
}
