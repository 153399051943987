<div class="cart" [ngClass]="{ 'with-search-bar': sharedVariablesService.config.SHOW_SEARCHBAR }">
  <div class="container">
    <h1 class="text-center">Finalisez votre commande</h1>
    <mat-horizontal-stepper [linear]="true" #cartStepper class="stepper">
      <mat-step label="Prenez rendez-vous" state="delivery" [editable]="!transactionCompleted" [completed]="appointment !== undefined" *ngIf="canHaveAppointment">
        <div class="delivery-choice" [ngClass]="{ hidden: showDeliveryFrame || appointment }" *ngIf="sharedVariablesService.transaction?.appointmentMakingDirective === 'OPTIONAL'">
          <h2>Avez-vous pris une option nécessitant un rendez-vous ?</h2>
          <p>Ex : Aide à l'installation, formation, ...</p>
          <div class="mt40">
            <button mat-stroked-button color="primary" class="mr20" (click)="noDelivery()">Non</button>
            <button mat-flat-button color="primary" (click)="showDelivery()">Oui</button>
          </div>
        </div>
        <div class="delivery-frame" [ngClass]="{ hidden: !showDeliveryFrame || appointment }">
          <iframe [src]="meetUrl" width="100%" height="750px"></iframe>
        </div>
        <div *ngIf="appointment">
          <div class="frame mt30 mb30">
            <h2 class="text-center">Votre rendez-vous</h2>
<!--            <p class="text-center"><strong>{{ appointment.date | dateformat: 'Europe/Paris' }} à {{ appointment.time.substr(0, 5) }}</strong></p>-->
            <p class="text-center"><strong>{{ appointment.date | formatdate: 'LL' }} à {{ appointment.time.substr(0, 5) }}</strong></p>
          </div>
          <div class="text-center"><button mat-flat-button color="primary" (click)="noDelivery()">Continuer</button></div>
        </div>
      </mat-step>
      <mat-step label="Validation" state="cart" [completed]="transactionCompleted" [editable]="!transactionCompleted">
        <div *ngIf="!transactionCompleted">
          <div class="row">
            <div class="col-xs-12 col-md-5">
              <div class="frame">
                <h2 class="text-center">Vos coordonnées</h2>
                <p><strong *ngIf="sharedVariablesService.customer.companyName">{{ sharedVariablesService.customer.companyName }}
                  <br></strong>
                  {{ sharedVariablesService.customer.firstName + ' ' + sharedVariablesService.customer.lastName }}
                </p>
                <p *ngIf="sharedVariablesService.customer.address.line1">{{ sharedVariablesService.customer.address.line1 }}
                  <span *ngIf="sharedVariablesService.customer.address.line2"><br>{{ sharedVariablesService.customer.address.line2 }}</span>
                  <span *ngIf="sharedVariablesService.customer.address.city"><br>{{ sharedVariablesService.customer.address.postalCode }} {{ sharedVariablesService.customer.address.city }}</span>
                </p>
                <p *ngIf="sharedVariablesService.customer.phone" >{{ sharedVariablesService.customer.phone | mask: '00 00 00 00 00' }}</p>
              </div>
              <div class="frame mt20" *ngIf="appointment">
                <h2 class="text-center">Votre rendez-vous</h2>
<!--                <p class="text-center"><strong>{{ appointment.date | dateformat: 'Europe/Paris' }} à {{ appointment.time.substr(0, 5) }}</strong></p>-->
                <p class="text-center"><strong>{{ appointment.date | formatdate: 'LL' }} à {{ appointment.time.substr(0, 5) }}</strong></p>
              </div>
            </div>
            <div class="col-xs-12 col-md-7">
              <div class="frame articles">
                <h2 class="text-center">Votre commande</h2>
                <div class="pack" *ngFor="let pack of sharedVariablesService.transaction.packs">
                  <div class="pack-headline">
                    <div class="pack-name">{{ pack.name }}</div>
                    <div class="pack-price">{{ pack.totalAmount | price }}</div>
                  </div>
                  <app-cart-item *ngFor="let item of pack.items;" [item]="item" [index]="item.rank" [readonly]="true"></app-cart-item>
                </div>
                <div *ngFor="let item of sharedVariablesService.transaction.items">
                  <app-cart-item *ngIf="!item.hasPackApplied()" [item]="item" [index]="item.rank" [readonly]="true"></app-cart-item>
                </div>
                <div *ngIf="sharedVariablesService.config.SHOW_SUBTOTAL_AND_DISCOUNTS">
                  <div class="row subtotal">
                    <div class="col-xs-6 col-sm-6 col-md-6">Sous-total</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 text-right price">{{ (sharedVariablesService.transaction.totalAmount - sharedVariablesService.transaction.totalDeliveryCostAmount + sharedVariablesService.transaction.totalDiscountAmount) | price }}</div>
                  </div>
                  <div class="row discount" *ngIf="sharedVariablesService.transaction.totalDiscountAmount > 0">
                    <div class="col-xs-6 col-sm-6 col-md-6">Total des remises</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 text-right price">-{{ sharedVariablesService.transaction.totalDiscountAmount | price }}</div>
                  </div>
                </div>
                <div *ngIf="sharedVariablesService.transaction.totalDeliveryCostAmount > 0">
                  <div class="row delivery mt20">
                    <div class="col-md-8 col-sm-8 col-xs-8"><mat-icon color="primary" class="align-middle mr10" svgIcon="delivery-truck-2"></mat-icon> <strong>Frais de port Chronopost</strong></div>
                    <div class="col-sm-4 col-md-4 col-xs-4 text-right price">{{ sharedVariablesService.transaction.totalDeliveryCostAmount | price }}</div>
                  </div>
                </div>
                <div *ngIf="sharedVariablesService.transaction.items.length === 0" class="text-center">Votre panier est vide</div>
                <div class="row total">
                  <div class="col-xs-6 col-sm-6 col-md-6">Total</div>
                  <div class="col-xs-6 col-sm-6 col-md-6 text-right price">{{ sharedVariablesService.transaction.totalAmount | price }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt20 text-center">
            <button [disabled]="sharedVariablesService.transaction.items.length === 0" mat-flat-button matStepperNext color="primary" (click)="validateOrder()">Valider</button>
          </div>
        </div>
        <div *ngIf="transactionCompleted" class="mt50 mb50 text-center">
          <h2>MERCI !</h2>
          <p>Un email de confirmation vient de vous être envoyé.</p>
          <button mat-stroked-button color="primary" class="mt20" [routerLink]="'/catalogue'">Retour au catalogue</button>
        </div>
      </mat-step>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="delivery">
        <mat-icon svgIcon="calendar"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="cart">
        <mat-icon svgIcon="shopping-bag"></mat-icon>
      </ng-template>
    </mat-horizontal-stepper>
  </div>
</div>
