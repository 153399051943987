import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Option } from './Option';
import { ProductVariant, ProductVariantAdapter } from './ProductVariant';

export interface Promotion {
  promotionName: string;
  discountType: string;
  packInstancesApplied?: string[];
}

export class Item {
  ean: string;
  name: string;
  description: string;
  quantity: number;
  salesUnitCount: number;
  unitPrice: number;
  unitGrossPrice: number;
  totalGrossAmount: number;
  totalAmount: number;
  totalDepositAmount: number;
  type: string;
  productId: string;
  productVariant: ProductVariant;
  options: Option[] = [];
  appliedPromotions: Promotion[];
  totalDiscountAmount: number;
  rank: number;

  constructor(ean: string,
              name: string,
              quantity: number,
              options?: Option[],
              productId?: string,
              description?: string,
              salesUnitCount?: number,
              unitPrice?: number,
              unitGrossPrice?: number,
              totalGrossAmount?: number,
              totalAmount?: number,
              totalDepositAmount?: number,
              type?: string,
              productVariant?: ProductVariant,
              appliedPromotions?: Promotion[],
              totalDiscountAmount?: number,
              rank?: number) {
    this.ean = ean;
    this.name = name;
    this.description = description;
    this.quantity = quantity;
    this.salesUnitCount = salesUnitCount;
    this.unitPrice = unitPrice;
    this.unitGrossPrice = unitGrossPrice;
    this.totalGrossAmount = totalGrossAmount;
    this.totalAmount = totalAmount;
    this.totalDepositAmount = totalDepositAmount;
    this.type = type;
    this.productId = productId;
    this.productVariant = productVariant;
    this.options = options;
    this.appliedPromotions = appliedPromotions;
    this.totalDiscountAmount = totalDiscountAmount;
    this.rank = rank;
  }

  hasOptions(): boolean {
    return this.options && this.options.length > 0;
  }

  hasPackApplied(): boolean {
    if (this.appliedPromotions && this.appliedPromotions.length > 0) {
      for (let appliedPromotion of this.appliedPromotions) {
        if (appliedPromotion.packInstancesApplied && appliedPromotion.packInstancesApplied.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ItemAdapter implements Adapter<Item> {
  adapt(item: any): Item {
    const productVariantAdapter = new ProductVariantAdapter();

    return new Item(
      item.ean,
      item.name,
      item.quantity,
      item.options ? item.options : undefined,
      item.productId,
      item.description,
      item.salesUnitCount,
      item.unitPrice,
      item.unitGrossPrice,
      item.totalGrossAmount,
      item.totalAmount,
      item.totalDepositAmount,
      item.type,
      item.productVariant ? productVariantAdapter.adapt(item.productVariant) : undefined,
      item.appliedPromotions ? item.appliedPromotions : undefined,
      item.totalDiscountAmount,
      item.rank
    );
  }

  prepare(object: Item): any {
    const target = {};

    Object.assign(target, object);
    delete target['productVariant'];
    delete target['rank'];

    return target;
  }
}
