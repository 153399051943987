import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({name: 'formatdate', pure: false})
export class FormatDatePipe implements PipeTransform {
  constructor() {
  }

  transform(timestamp: string|Date, format: string = 'L LT'): string {
    if (!timestamp || !format) {
      return undefined;
    }

    return moment(timestamp.toString()).tz(moment.tz.guess()).locale('fr-FR').format(format);
  }
}
