import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Category, CategoryAdapter } from './Category';
import { Product, ProductAdapter } from './Product';

export class PackSelection {

  productIds: { [name: string]: number }; // Number is quantity of products for a named product
  categoryIds: { [name: string]: number }; // Number is quantity of products for a named category
  products: Product[] = [];
  categories: Category[];

  constructor(productIds?: { [p: string]: number }, categoryIds?: { [p: string]: number }, products?: Product[], categories?: Category[]) {
    this.productIds = productIds;
    this.categoryIds = categoryIds;
    this.products = products;
    this.categories = categories;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PackSelectionAdapter implements Adapter<PackSelection> {

  adapt(item: any): PackSelection {
    const productAdapter: ProductAdapter = new ProductAdapter();
    const categoryAdapter: CategoryAdapter = new CategoryAdapter();

    return new PackSelection(
      item.productIds,
      item.categoryIds,
      item.products ? item.products.map(e => productAdapter.adapt(e)) : undefined,
      item.categories ? item.categories.map(e => categoryAdapter.adapt(e)) : undefined
    );
  }
}
