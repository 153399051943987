import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OAuth2Client } from '../model/OAuth2Client';
import { User, UserAdapter } from '../model/User';
import {Customer, CustomerAdapter} from '../model/Customer';
import { map, switchMap } from 'rxjs/operators';
import {SharedVariablesService} from './shared-variables.service';
import {ApiService} from './api.service';
import {CustomerService} from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ApiService {
  public currentUserCustomer: Observable<User|Customer>;
  public currentUserCustomerSubject: BehaviorSubject<User|Customer>;
  readonly instanceId: string = 'giphar';

  constructor(private http: HttpClient,
              private router: Router,
              private userAdapter: UserAdapter,
              private customerAdapter: CustomerAdapter,
              private customerService: CustomerService,
              private sharedVariablesService: SharedVariablesService) {
    super();

    if (sessionStorage.getItem('customer')) {
      this.currentUserCustomerSubject = new BehaviorSubject<Customer>(this.customerAdapter.adapt(JSON.parse(window.sessionStorage.getItem('customer'))));
    } else {
      this.currentUserCustomerSubject = new BehaviorSubject<User | Customer>(undefined);
    }
    this.currentUserCustomer = this.currentUserCustomerSubject.asObservable();
  }

  getCurrentUser(): User | Customer {
    return this.currentUserCustomerSubject.getValue();
  }

  /**
   * Login method.
   * @param userEmail
   * @param userPassword
   * @param type
   */
  login(userEmail: string, userPassword: string, type: string = 'USER'): Observable<User> {
    const loginParams = {email: userEmail, password: userPassword, instanceId: this.sharedVariablesService.instance, type};

    return this.http.post<User>(this.baseUriNoAuthKey + 'identity/authenticate', loginParams).pipe(
      map((item: any) => {
        const tmpUser = this.userAdapter.adapt(item);
        this.currentUserCustomerSubject.next(tmpUser);
        this.saveUser();
        return tmpUser;
      })
    );
  }

  /**
   * Login method for customer.
   * @param userEmail
   * @param userPassword
   * @param type
   */
  customerLogin(userEmail: string, userPassword: string, type: string = 'CUSTOMER'): Observable<Customer> {
    const loginParams = {email: userEmail, password: userPassword, instanceId: this.sharedVariablesService.instance, type};

    return this.http.post<Customer>(this.baseUriNoAuthKey + 'identity/authenticate', loginParams).pipe(
      switchMap((item: Customer) => {
        this.currentUserCustomerSubject.next(item);
        this.saveUser();
        return this.customerService.getCustomer(item.id).pipe(map((customer: Customer) => {
          customer.token = item.token;
          this.currentUserCustomerSubject.next(customer);
          this.saveUser();
          return customer;
        }));
      })
    );
  }

  /**
   * Login method with token.
   */
  loginWithToken(token?: string): Observable<User|Customer> {
    let headers = new HttpHeaders();
    if (token !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<User|Customer>(this.baseUriNoAuthKey + 'identity/me', {headers}).pipe(
      switchMap((item: User|Customer) => {
        item.token = token;
        this.currentUserCustomerSubject.next(item);
        this.saveUser();

        if (item.authType === 'CUSTOMER') {
          return this.customerService.getCustomer(item.id).pipe(map((customer: Customer) => {
            customer.token = item.token;
            this.currentUserCustomerSubject.next(customer);
            this.saveUser();
            return customer;
          }));
        } else {
          return this.http.get<User|Customer>(this.baseUriNoAuthKey + 'identity/me', {headers});
        }
      })
    );
  }

  /**
   * Save current user in sessionStorage.
   */
  saveUser() {
    this.sharedVariablesService.customer = this.customerAdapter.adapt(this.getCurrentUser());
    sessionStorage.setItem('customer', JSON.stringify(this.getCurrentUser()));
  }

  /**
   * Request a reset password.
   * @param userEmail
   */
  requestResetPassword(userEmail: string) {
    let headers = new HttpHeaders();
    headers = headers.set('InstanceId', this.instanceId);

    return this.http.get('/api/customers/' + userEmail + '/resetPassword', { headers });
  }

  /**
   * Reset password.
   * @param userEmail
   * @param token
   * @param password
   */
  resetPassword(userEmail: string, token: string, password: string) {
    return this.http.post('/api/customers/' + userEmail + '/password/' + token, { password });
  }

  /**
   * Logout method.
   */
  logout(redirectToLogin: boolean = true): void {
    this.sharedVariablesService.clear();
    this.currentUserCustomer = undefined;
    this.currentUserCustomerSubject.next(undefined);
    window.sessionStorage.removeItem('customer');
    if (redirectToLogin) {
      this.router.navigate(['/']);
    }
  }

  /**
   * Return available OAuth2Clients
   */
  getOAuth2Clients(): Observable<OAuth2Client[]> {
    return this.http.get<OAuth2Client[]>(this.baseUriNoAuthKey + 'oauth2/clients');
  }
}
