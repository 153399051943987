import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductTag, ProductTagAdapter } from '../model/ProductTag';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends ApiService {

  constructor(private http: HttpClient,
              private productTagAdapter: ProductTagAdapter) {
    super();
  }

  getTags() {
    const params = new HttpParams();
    return this.http.get<ProductTag[]>(this.baseUri + 'productTags', {params})
      .pipe(map((data: any[]) => data.map(item => this.productTagAdapter.adapt(item))));
  }

  getTag(id: string) {
    const params = new HttpParams();
    return this.http.get<ProductTag>(this.baseUri + 'productTags/' + id, {params}).pipe(
      map(item => this.productTagAdapter.adapt(item)));
  }

  deleteTag(id: string) {
    const params = new HttpParams();
    return this.http.delete(this.baseUri + 'productTags/' + id, {params});
  }

  createTag(productTag: ProductTag): Observable<ProductTag> {
    const params = new HttpParams();
    return this.http.post<ProductTag>(this.baseUri + 'productTags', productTag, {params}).pipe(
      map(item => this.productTagAdapter.adapt(item)));
  }

  updateTag(productTag: ProductTag): Observable<ProductTag> {
    const params = new HttpParams();
    return this.http.post<ProductTag>(this.baseUri + 'productTags/' + productTag.id, productTag, {params})
      .pipe(map(item => this.productTagAdapter.adapt(item)));
  }
}
