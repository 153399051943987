import {Component, Input, OnInit} from '@angular/core';
import {ProductScheme} from '../../lib/model/ProductScheme';
import {Product} from '../../lib/model/Product';
import {Option} from '../../lib/model/Option';
import { ProductTag } from '../../lib/model/ProductTag';
import { Transaction } from '../../lib/model/Transaction';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {Title} from '@angular/platform-browser';
import { TagsService } from '../../lib/services/tags.service';
import {TransactionService} from '../../lib/services/transaction.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../lib/services/product.service';
import {MatDialog} from '@angular/material/dialog';
import {GoogleAnalyticsService} from '../../lib/services/google-analytics.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OptionsDialogComponent} from '../options-dialog/options-dialog.component';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {

  @Input() product: Product;
  public isAlreadyInTransaction = false;
  public loading = false;
  public animate = false;
  public static productScheme: ProductScheme;
  public static productSchemeLoading = false;
  public static tagsMap: { [id: string]: ProductTag };
  public static tagsMapLoading = false;

  constructor(public sharedVariablesService: SharedVariablesService,
              protected titleService: Title,
              private transactionService: TransactionService,
              private ngxLoaderService: NgxUiLoaderService,
              private translateService: TranslateService,
              protected productService: ProductService,
              private tagService: TagsService,
              private dialog: MatDialog,
              public googleAnalyticsService: GoogleAnalyticsService,
              private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.sharedVariablesService && this.sharedVariablesService.transaction) {
      this.isAlreadyInTransaction =
          this.sharedVariablesService.transaction.containsProduct(this.product) && this.product.hasOptions() ||
          this.sharedVariablesService.transaction.containsProductWithOption(this.product) && this.product.hasOptions();
    }

    if (!ProductItemComponent.productScheme && !ProductItemComponent.productSchemeLoading) {
      ProductItemComponent.productSchemeLoading = true;
      lastValueFrom(this.productService.getProductScheme()).then((productScheme) => {
        ProductItemComponent.productScheme = productScheme;
        ProductItemComponent.productSchemeLoading = false;
      });
    }

    // Get tags information
    if (!ProductItemComponent.tagsMap && !ProductItemComponent.tagsMapLoading) {
      ProductItemComponent.tagsMapLoading = true;
      ProductItemComponent.tagsMap = {};
      lastValueFrom(this.tagService.getTags()).then((tags) => {
        ProductItemComponent.tagsMapLoading = false;
        tags.forEach(tag => {
          ProductItemComponent.tagsMap[tag.id] = tag;
        });
      });
    }
  }

  getTag(id: string): ProductTag|undefined {
    if (ProductItemComponent.tagsMap && ProductItemComponent.tagsMap[id]) {
      return ProductItemComponent.tagsMap[id];
    } else {
      return undefined;
    }
  }

  askForOptions(product): Promise<number> {
    if (product.options && product.options.length > 0) {
      const dialogRef = this.dialog.open(OptionsDialogComponent, {
        width: '800px',
        data: {options: [], product}
      });

      dialogRef.afterClosed().subscribe(options => {
        if (options) {
          return this.addToCart(product, options);
        } else {
          return Promise.resolve(0);
        }
      });
    } else {
      return this.addToCart(product);
    }
  }

  addToCart(product: Product, options?: Option[]): Promise<number> {
    const self = this;

    this.loading = true;
    this.animate = true;

    return new Promise((resolve, reject) => {
      this.createTransactionIfNeeded().then((transaction) => {
        lastValueFrom(this.transactionService.addProduct(this.sharedVariablesService.transaction, product, 1, options))
          .then((resultTransaction) => {
            this.sharedVariablesService.transaction = resultTransaction;
            this.isAlreadyInTransaction = this.sharedVariablesService.transaction.containsProduct(this.product) && !this.product.hasOptions() ||
              this.sharedVariablesService.transaction.containsProductWithOption(this.product) && this.product.hasOptions();

            this.loading = false;
            this.animate = false;

            self.snackbar.open('Panier mis à jour avec succès', undefined, {
              panelClass: ['success-snackbar'],
              duration: 2500,
            });

            this.googleAnalyticsService.addItemToCart(this.product, 'product_card');

            resolve(1);
          }, (error) => {
            resolve(0);
            this.loading = false;
          });
      });
    });
  }

  decreaseQuantity(product: Product): Promise<number> {
    const self = this;

    this.loading = true;

    return new Promise((resolve, reject) => {
      if (this.sharedVariablesService.transaction.itemsAsObject[product.id]) {
        this.sharedVariablesService.transaction.updateProductInTransaction(product, this.sharedVariablesService.transaction.itemsAsObject[product.id][product.ean] - 1);
        lastValueFrom(this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction)).then((transaction) => {
          this.sharedVariablesService.transaction = transaction;
          this.isAlreadyInTransaction = this.sharedVariablesService.transaction.containsProduct(this.product) && this.product.hasOptions() ||
            this.sharedVariablesService.transaction.containsProductWithOption(this.product) && this.product.hasOptions();

          this.loading = false;

          self.snackbar.open('Panier mis à jour avec succès', undefined, {
            panelClass: ['success-snackbar'],
            duration: 2500,
          });

          this.googleAnalyticsService.removeItemFromCart(this.product, 'product_card');

          resolve(-1);
        }, (error) => {
          resolve(0);
        });
      } else {
        reject();
      }
    });
  }

  createTransactionIfNeeded(): Promise<Transaction> {
    return new Promise((resolve, reject) => {
      // We create transaction if none
      let transaction: Transaction;
      if (!this.sharedVariablesService.transaction) {
        transaction = new Transaction(
          undefined,
          this.sharedVariablesService.saleChannel.code,
          this.sharedVariablesService.customer,
          undefined,
          'central01',
          this.sharedVariablesService.saleChannel.code,
          'WEB'
        );

        this.ngxLoaderService.start();

        lastValueFrom(this.transactionService.createOrUpdateTransaction(transaction)).then((resultTransaction) => {
          this.sharedVariablesService.transaction = resultTransaction;
          resolve(resultTransaction);
          this.ngxLoaderService.stop();
        });
      } else {
        transaction = this.sharedVariablesService.transaction;
        resolve(transaction);
      }
    });
  }

  getProductScheme(): ProductScheme {
    return ProductItemComponent.productScheme;
  }

  clickProduct(): void {
    this.googleAnalyticsService.selectItem(this.product, undefined);
  }
}
