import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Range, RangeAdapter } from './Range';

export class Category {
  code: string;
  name: string;
  parentCategoryCode?: string = undefined;
  pictureUri?: string = undefined;
  highlight?: boolean = undefined;

  children?: Category[] = [];

  parent?: Category = undefined;

  rank?: number = undefined;
  active?: boolean = undefined;

  creationDate?: Date = undefined;
  modificationDate?: Date = undefined;

  saleChannelCodes?: string[] = undefined;
  ranges?: Range[] = undefined;

  constructor(code?: string, name?: string, parentCategoryCode?: string, pictureUri?: string, highlight?: boolean, children?: Category[],
              parent?: Category, rank?: number, active?: boolean, creationDate?: Date, modificationDate?: Date, saleChannelCodes?: string[],
              ranges?: Range[]) {
    this.code = code;
    this.name = name;
    this.parentCategoryCode = parentCategoryCode;
    this.pictureUri = pictureUri;
    this.highlight = highlight;
    this.children = children;
    this.parent = parent;
    this.rank = rank;
    this.active = active;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.saleChannelCodes = saleChannelCodes;
    this.ranges = ranges;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CategoryAdapter implements Adapter<Category> {

  adapt(item: any): Category {
    const categoryAdapter: CategoryAdapter = new CategoryAdapter();
    const rangeAdapter: RangeAdapter = new RangeAdapter();

    return new Category(
      item.code,
      item.name,
      item.parentCategoryCode,
      item.pictureUri,
      item.highlight,
      item.children ? item.children.map((e) => {
        e = categoryAdapter.adapt(e);
        return e;
      }) : undefined,
      item.parent ? categoryAdapter.adapt(item.parent) : undefined,
      item.rank,
      item.active,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.saleChannelCodes,
      item.ranges ? item.ranges.map((e) => {
        e = rangeAdapter.adapt(e);
        return e;
      }) : undefined
    );
  }
}
