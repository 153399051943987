import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Page } from './Page';
import { Offer, OfferAdapter } from './Offer';

export class OfferPage extends Page {
  content: Offer[];

  constructor(totalPages?: number, totalElements?: number, size?: number, currentPage?: number, content?: Offer[]) {
    super(totalPages, totalElements, size, currentPage);
    this.content = content;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferPageAdapter implements Adapter<OfferPage> {

  adapt(item: any): OfferPage {
    const offerAdapter: OfferAdapter = new OfferAdapter();

    return new OfferPage(
      item.totalPages,
      item.totalElements,
      item.size,
      item.currentPage,
      item.content ? item.content.map(e => offerAdapter.adapt(e)) : []
    );
  }
}
