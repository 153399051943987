import { Injectable } from '@angular/core';
import slugify from 'slugify';
import { Adapter } from '../interfaces/adapter.interface';
import {
  ProductDeclination,
  ProductDeclinationAdapter,
  ProductDeclinationImage,
  ProductImageType
} from './ProductDeclination';
import { ProductOption } from './ProductOption';

export interface Brand {
  name: string;
  code: string;
  urlImage: string;
}

export interface RelatedProduct {
  id: string;
}

export interface ProductProperties {
  details_care_instructions: string[];
  washing_advice: string[];
  description_details: string[];
  selling_point: string[];
  selling_point_details: string[];
  technical_specifications: string[];
  divers: string[];
  frequency: string[];
  allergen: string[];
  conservationCondition: string[];
  defaultQuota: string[];
  eatByDate: string[];
  netQuantity: string[];
  nutritionalDeclaration: string[];
  origin: string[];
  pricePerWeight: string[];
  productTreatment: string[];
  recipeComposition: string[];
  supplierContact: string[];
  commercialName: string[];
}

export class Product {
  id: string;
  name: string;
  availableCount: number = -1;
  description: string;
  shortDescription: string;
  rangeCodes: string[] = [];
  mainCategoryCode: string;
  categoryCodes: string[] = [];
  vatCode: string;
  active: boolean;
  organisationId: string;
  type: string;
  eans: string[] = [];
  ean: string;
  sku: string;
  reference: string;
  maxQuantityInTransaction: number;
  images: ProductDeclinationImage[] = [];
  saleChannelCodes: string[] = [];
  brand: Brand;
  salesUnitCount: number;
  price: number;
  minPrice: number;
  maxPrice: number;
  depositAmount: number = 0;
  tags: string[] = [];
  creationDate: Date;
  modificationDate: Date;
  productDeclinations: ProductDeclination[] = [];
  properties: {} = {};
  relatedProducts: RelatedProduct[] = [];
  options: ProductOption[] = [];
  weight: number;
  stock: number;
  /**
   * Selected declination (first of list by default). Not received / sent to the API.
   */
  selectedDeclination: ProductDeclination = undefined;

  constructor(id: string,
              name: string,
              availableCount: number,
              description: string,
              shortDescription: string,
              rangeCodes: string[],
              mainCategoryCode: string,
              categoryCodes: string[],
              vatCode: string,
              active: boolean,
              organisationId: string,
              type: string,
              eans: string[],
              ean: string,
              sku: string,
              reference: string,
              maxQuantityInTransaction: number,
              images: ProductDeclinationImage[],
              saleChannelCodes: string[],
              brand: Brand,
              salesUnitCount: number,
              price: number,
              minPrice: number,
              maxPrice: number,
              depositAmount: number,
              tags: string[],
              creationDate: Date,
              modificationDate: Date,
              productDeclinations: ProductDeclination[],
              properties: ProductProperties,
              relatedProducts: RelatedProduct[],
              options: ProductOption[],
              weight?: number,
              stock?: number) {
    this.id = id;
    this.name = name;
    this.availableCount = availableCount;
    this.description = description;
    this.shortDescription = shortDescription;
    this.rangeCodes = rangeCodes;
    this.mainCategoryCode = mainCategoryCode;
    this.categoryCodes = categoryCodes;
    this.vatCode = vatCode;
    this.active = active;
    this.organisationId = organisationId;
    this.type = type;
    this.eans = eans;
    this.ean = ean;
    this.sku = sku;
    this.reference = reference;
    this.maxQuantityInTransaction = maxQuantityInTransaction;
    this.images = images;
    this.saleChannelCodes = saleChannelCodes;
    this.brand = brand;
    this.salesUnitCount = salesUnitCount;
    this.price = price;
    this.minPrice = minPrice;
    this.maxPrice = maxPrice;
    this.depositAmount = depositAmount;
    this.tags = tags;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.productDeclinations = productDeclinations;
    this.properties = properties;
    this.relatedProducts = relatedProducts;
    this.options = options;
    this.weight = weight;
    this.stock = stock;

    if (this.productDeclinations.length > 0) {
      this.selectedDeclination = productDeclinations[0];
    }
  }

  /**
   * Returns true if the product-item has the tag in parameter.
   * @param tagName
   */
  public hasTag(tagName: string): boolean {
    return this.tags && this.tags.indexOf(tagName) > -1;
  }

  /**
   * Select a specific declination considering the id provided.
   * @param declinationId
   */
  public selectDeclination(declinationId: string) {
    if (this.productDeclinations.length > 0) {
      for (const declination of this.productDeclinations) {
        if (declinationId === declination.id) {
          this.selectedDeclination = declination;
          return;
        }
      }
    }
  }

  public slugify(): string {
    return slugify(this.name, {replacement: '_', strict: true});
  }

  public hasOptions() {
    return this.options && this.options.length > 0;
  }

  public getDefaultImage(): ProductDeclinationImage {
    let fallbackImage = undefined;

    for (const image of this.images) {
      if (image.type === ProductImageType.IMAGE_DEFAULT) {
        return image;
      } else if (image.name.indexOf('default') > 0) {
        fallbackImage = image;
      }
    }

    if (!fallbackImage && this.images.length > 0) {
      return this.images[0];
    } else {
      return fallbackImage;
    }
  }

  public getNameOrCommercialName(): string {
    if (this.properties > 0 && this.properties['commercialName']) {
      return this.properties['commercialName'];
    } else {
      return this.name;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductAdapter implements Adapter<Product> {
  adapt(item: any): Product {
    const productDeclinationAdapter = new ProductDeclinationAdapter();

    return new Product(
      item.id,
      item.name,
      item.availableCount,
      item.description,
      item.shortDescription,
      item.rangeCodes,
      item.mainCategoryCode,
      item.categoryCodes,
      item.vatCode,
      item.active,
      item.organisationId,
      item.type,
      item.eans,
      item.ean,
      item.sku,
      item.reference,
      item.maxQuantityInTransaction,
      item.images ? item.images : [],
      item.saleChannelCodes,
      item.brand,
      item.salesUnitCount,
      (item.price && item.price.normalPrice) ? item.price.normalPrice : (item.minPrice ? item.minPrice : undefined),
      item.minPrice,
      item.maxPrice,
      item.depositAmount,
      item.tags,
      item.creationDate ? new Date(item.creationDate) : undefined,
      item.modificationDate ? new Date(item.modificationDate) : undefined,
      item.productDeclinations ? item.productDeclinations.map(elt => productDeclinationAdapter.adapt(elt)) : [],
      item.properties,
      item.relatedProducts ? item.relatedProducts : [],
      item.options ? item.options : [],
      item.weight,
      item.stock,
    );
  }

  prepare(object: Product): any {
    let target = {};
    Object.assign(target, object);
    delete target['productDeclinations'];

    return target;
  }
}
