import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { OrderLineStatus } from './OrderLine';

export class OrderLineStatusEvent {
  orderLineStatus: OrderLineStatus;
  date: Date;
  userId: string;

  constructor(orderLineStatus?: OrderLineStatus, date?: Date, userId?: string) {
    this.orderLineStatus = orderLineStatus;
    this.date = date;
    this.userId = userId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderLineStatusEventAdapter implements Adapter<OrderLineStatusEvent> {
  adapt(item: any): OrderLineStatusEvent {
    return new OrderLineStatusEvent(
      item.orderLineStatus,
      item.orderLineStatus ? new Date(item.date.toString()) : undefined,
      item.userId
    );
  }

  prepare(object: OrderLineStatusEvent): any {
    const target = {};
    Object.assign(target, object);

    return target;
  }
}

export enum OrderStatus  {
  WAITING_FOR_PICKING = 'WAITING_FOR_PICKING',
  TO_PREPARE = 'TO_PREPARE',
  IN_PREPARATION = 'IN_PREPARATION',
  WAITING_FOR_WITHDRAWAL = 'WAITING_FOR_WITHDRAWAL',
  DISCARDED = 'DISCARDED',
  WITHDRAWN = 'WITHDRAWN',
  TO_SEND = 'TO_SEND',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum OrderType {
  WITHDRAWAL = 'WITHDRAWAL',
  DELIVERY = 'DELIVERY',
}
