import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Page } from './Page';
import { Order, OrderAdapter } from './Order';

export class OrderPage extends Page {
  content: Order[];

  constructor(totalPages?: number, totalElements?: number, size?: number, currentPage?: number, content?: Order[]) {
    super(totalPages, totalElements, size, currentPage);
    this.content = content;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderPageAdapter implements Adapter<OrderPage> {

  adapt(item: any): OrderPage {
    const orderAdapter: OrderAdapter = new OrderAdapter();

    return new OrderPage(
      item.totalPages,
      item.totalElements,
      item.size,
      item.currentPage,
      item.content ? item.content.map(e => orderAdapter.adapt(e)) : []
    );
  }
}
