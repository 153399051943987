import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class SaleChannelProperties {
  pictureUri: string;
  idIngenico: string;
  redirectLink: string;
  confirmationLink: string;
  selpOrderFluxId: string;

  constructor(item?: any) {
    if (item) {
      for (const [key, value] of Object.entries(item)) {
        this[key] = value.toString();
      }
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class SaleChannelPropertiesAdapter implements Adapter<SaleChannelProperties> {
  adapt(item: any): SaleChannelProperties {
    if (!item) {
      return new SaleChannelProperties();
    }
    return new SaleChannelProperties(item);
  }

  prepare(object: SaleChannelProperties): any {
    const target = {};
    for (const [key, value] of Object.entries(object)) {
      if (value && value !== '') {
        target[key] = value.toString();
      }
    }

    return target;
  }
}

export class Withdrawal {
  daysCount: number;
  hoursSupported: boolean = true;
  interval: string = 'PT30M';
  format: string = 'LIST';
  hoursFormat: string = 'LIST';
  startDate: Date;
  endDate: Date;

  constructor(daysCount?: number, hoursSupported: boolean = false, interval?: string, format?: string, hoursFormat?: string, startDate?: Date, endDate?: Date) {
    this.daysCount = daysCount;
    this.hoursSupported = hoursSupported;
    this.interval = interval;
    this.format = format;
    this.hoursFormat = hoursFormat;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WithdrawalAdapter implements Adapter<Withdrawal> {
  adapt(item: any): Withdrawal {
    if (!item) {
      return new Withdrawal();
    }
    return new Withdrawal(
      item.daysCount,
      item.hoursSupported,
      item.interval,
      item.format,
      item.hoursFormat,
      new Date(item.startDate),
      new Date(item.endDate),
    );
  }
}

export class SaleChannel {
  saleChannelClass: string;
  type: string;
  email: string;
  phone: string;
  code: string;
  name: string;
  title: string;
  baseUrl: string;
  active: boolean;
  properties: SaleChannelProperties;
  withdrawal: Withdrawal;
  closedMessage: string;
  closedImageUrl: string;
  closedRedirectUrl: string;
  openingDate: Date;
  closingDate: Date;

  constructor(saleChannelClass?: string, type?: string, email?: string, phone?: string, code?: string, name?: string, title?: string, baseUrl?: string, active: boolean = false, properties?: SaleChannelProperties, withdrawal?: Withdrawal, closedMessage?: string, closedImageUrl?: string, closedRedirectUrl?: string, openingDate?: Date, closingDate?: Date) {
    this.saleChannelClass = saleChannelClass;
    this.type = type;
    this.email = email;
    this.phone = phone;
    this.code = code;
    this.name = name;
    this.title = title;
    this.baseUrl = baseUrl;
    this.active = active;
    this.properties = properties;
    this.withdrawal = withdrawal;
    this.closedMessage = closedMessage;
    this.closedImageUrl = closedImageUrl;
    this.closedRedirectUrl = closedRedirectUrl;
    this.openingDate = openingDate;
    this.closingDate = closingDate;
  }

  /**
   * Returns whether the Sale Channel was, is or will be opened depending of its active setting and opening + closing dates.
   * @return int -1 if opened in past or closed, 0 if opened now and +1 if opened in the future.
   */
  public exhibitionOpeningTime() {
    const now = new Date();
    // Opened now
    if (this.active && (!this.openingDate || (this.openingDate && this.closingDate &&
      this.openingDate <= now
      && this.closingDate > now))) {
      return 0;
    } else if (this.active && this.openingDate && this.openingDate > now) {
      return 1; // Opened in future
    } else {
      return -1; // Opened in past or closed
    }
  }

  /**
   * Return whether the sale channel is open (active and between opening and closing dates).
   * @param considerFutureAsOpen If true, the method also returns true is the sale channel is to be opened in the future.
   */
  public isOpen(considerFutureAsOpen: boolean = false): boolean {
    return (this.exhibitionOpeningTime() === 0) || (considerFutureAsOpen && this.exhibitionOpeningTime() > 0);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SaleChannelAdapter implements Adapter<SaleChannel> {

  adapt(item: any): SaleChannel {
    const saleChannelPropertiesAdapter: SaleChannelPropertiesAdapter = new SaleChannelPropertiesAdapter();
    const withdrawalAdapter: WithdrawalAdapter = new WithdrawalAdapter();
    return new SaleChannel(
      item.class,
      item.type,
      item.email,
      item.phone,
      item.code,
      item.name,
      item.title,
      item.baseUrl,
      item.active,
      saleChannelPropertiesAdapter.adapt(item.properties),
      withdrawalAdapter.adapt(item.withdrawal),
      item.closedMessage,
      item.closedImageUrl,
      item.closedRedirectUrl,
      item.openingDate ? new Date(item.openingDate.toString()) : undefined,
      item.closingDate ? new Date(item.closingDate.toString()) : undefined,
    );
  }

  prepare(object: SaleChannel): any {
    const saleChannelPropertiesAdapter: SaleChannelPropertiesAdapter = new SaleChannelPropertiesAdapter();
    const target = {};
    Object.assign(target, object);
    target['class'] = object.saleChannelClass;
    target['properties'] = saleChannelPropertiesAdapter.prepare(object.properties);

    return target;
  }
}
