import {Directive, Output, ElementRef, EventEmitter, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[clickoutside]'
})
export class ClickOutsideDirective {

  @Output() clickoutside: EventEmitter<any>;
  @Input() enableBlackListing: boolean;
  constructor(private elementRef: ElementRef) {
    this.clickoutside = new EventEmitter<any>();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {

    const isInsideClicked = this.elementRef.nativeElement.contains(targetElement);
    /**
     * If blacklisting is enabled this directive will only
     * emit if the clicked element is outside and has not a class blacklisted
     * In all the other cases it will emit if we click outside
     */
    if(this.enableBlackListing) {
      const isBlackListed = targetElement.classList.contains('blacklisted');
      if(!isInsideClicked && !isBlackListed) {
        this.clickoutside.emit(null);
      }
    } else {
      this.clickoutside.emit(null);
    }
  }

}
