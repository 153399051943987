import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './lib/helpers/auth-guards';
import {CartComponent} from './pages/cart/cart.component';
import {OauthComponent} from './pages/login/oauth.component';
import {LoginComponent} from './pages/login/login.component';
import {ProductsComponent} from './pages/products/products.component';
import {DataGuard} from './lib/helpers/data-guards';
import {ProductComponent} from './pages/product/product.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'oauth2/redirect',
    component: OauthComponent,
  },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'catalogue',
    component: ProductsComponent,
    data: {reuseRoute: true},
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    component: ProductsComponent,
    data: {forceReload: true},
    canActivate: [AuthGuard],
  },
  {
    path: 'panier',
    component: CartComponent,
    data: {forceReload: true},
    canActivate: [DataGuard, AuthGuard],
  },
  {
    path: 'catalogue/:categorycode',
    component: ProductsComponent,
    data: {reuseRoute: true},
    canActivate: [AuthGuard],
  },
  {
    path: 'product/:ean/:slug',
    component: ProductComponent,
    data: {forceReload: true},
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
